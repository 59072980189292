import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {PasswordValidator} from "@shared/validators/password";
import {IPassword} from '@models/dist';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  @Output()
  formChangeEvent = new EventEmitter<IPassword>();
  public displayPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder) {
  }

  get errorControl() {
    return this.infosForm?.controls;
  }

  ngOnInit(): void {
  }


  keyupEnter() {
    this.formChangeEvent.emit(this.infosForm.value);
  }

  private checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const pass = group.get('password')?.value;
    const confirmPass = group.get('confirmPassword')?.value;
    return pass === confirmPass ? null : {notSame: true};
  };


  infosForm: FormGroup = this.formBuilder.group(
    {
      password: ['', [PasswordValidator.isValid]],
      confirmPassword: [''],
    },
    {validators: this.checkPasswords}
  );


}
