import {InfoComponent} from "@shared/components/info/info.component";
import {LangComponent} from "@shared/components/lang/lang.component";
import {SetPasswordComponent} from "@shared/components/set-password/set-password.component";
import {LayoutComponent} from "@shared/components/layout/layout.component";
import {FooterComponent} from "./footer/footer.component";
import {HeaderComponent} from "./header/header.component";
import {LogoutComponent} from "@shared/components/logout/logout.component";
import {TwofaTriggerComponent} from "@shared/components/twofa-trigger/twofa-trigger.component";
import {BtnComponent} from "@shared/components/btn/btn.component";
import {TwoFaGenericModalComponent} from "./twofa-modal-generic/twofa-generic.modal.component";
import {ConfirmationModalComponent} from "@shared/components/confirmation-modal/confirmation-modal.component";
import {DefaultTableComponent} from "@shared/components/default-table/default-table.component";
import {SummitLogoComponent} from "@shared/components/summit-logo/summit-logo.component";
import { IcoBtnComponent } from "./ico-btn/ico-btn.component";
import {ChangeUserEmailComponent} from "@shared/components/change-user-email/change-user-email.component";
export {
  InfoComponent,
  LangComponent,
  SetPasswordComponent,
  LayoutComponent,
  HeaderComponent,
  FooterComponent,
  LogoutComponent,
  TwofaTriggerComponent,
  BtnComponent,
  TwoFaGenericModalComponent,
  ConfirmationModalComponent,
  DefaultTableComponent,
  SummitLogoComponent,
  IcoBtnComponent,
  ChangeUserEmailComponent
};

export const COMPONENTS = [
  InfoComponent,
  LangComponent,
  SetPasswordComponent,
  LayoutComponent,
  HeaderComponent,
  FooterComponent,
  LogoutComponent,
  TwofaTriggerComponent,
  BtnComponent,
  TwoFaGenericModalComponent,
  ConfirmationModalComponent,
  DefaultTableComponent,
  SummitLogoComponent,
  IcoBtnComponent,
  ChangeUserEmailComponent
];
