import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export class MiscHelpers {

  static clone(value: any) {
    if (!value) {
      return value;
    }
    return JSON.parse(JSON.stringify(value));
  }

  /**
   * copyToClipBoard
   */
  static copyToClipboard(trackingReference: string) {
    const el = document.createElement('textarea');
    el.value = trackingReference;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  static firestoreDocsToArray(docs: any[]): any[] {
    const returnArr: any[] = [];
    let index = 0;
    docs.forEach(d => {
      if (d.exists) {
        const data = d.data();
        data.ref = d.ref;
        data.documentId = d.id;
        data.index = index;
        returnArr.push(data);
        index++;
      }
    })

    return returnArr;
  }


  static snapshotToArray(querySnapshot: any): any[] {
    const returnArr: any[] = [];
    if (querySnapshot) {
      querySnapshot.forEach((doc: any) => {
        // doc.data() is never undefined for query doc snapshots
        const item = doc.data();
        if (item) {
          item.id = doc.id;
          item.timestamp = Math.abs(item.timestamp);
          returnArr.push(item);
        }
      });
    }

    return returnArr;
  }

  static convertArrayToCsv(rows: any[]): string {
    if (!rows?.length) {
      return '';
    }

    const items = rows;
    const replacer = (key: any, value: any) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(items[0]);

    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => {
        return JSON.stringify(row[fieldName], replacer);
      }).join(','))
    ].join('\r\n');

    return csv;
  }

  static capitalizeFirstLetter(text: string): string {
    if (!text) {
      return '';
    }
    text = text.toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  static checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const pass = group.get('password')?.value;
    const confirmPass = group.get('confirmPassword')?.value;
    return pass === confirmPass ? null : {notSame: true};
  };


  static convertTimestampToDayMonthYear(timestamp: number): string {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${day}/${month}/${year}_${hours}:${minutes}`;
  }

}
