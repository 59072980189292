<div class="layout-menu-container flex">
  sidebar
<!--  // FIRST SIDEBAR-->
<!--  <ul-->
<!--    class="layout-menu flex flex-column justify-content-between  directory-list"-->
<!--    role="menu">-->
<!--    <div class="directory-list-inside mt-3">-->
<!--      <div-->
<!--        *ngFor="let menu of menus"-->
<!--        class="directoryButton"-->
<!--        (click)="selectMenu(menu)"-->
<!--        [class.active]="menu.active"-->
<!--      >-->
<!--        <app-summit-logo [hover]="true" [active]="menu.active" [service]="menu.slug"></app-summit-logo>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash;    // BOTOM LINK&ndash;&gt;-->
<!--    <div class="flex flex-column justify-content-center align-items-center">-->
<!--      <div (click)="openLanguageModal()" class="mt-2 cursor">-->
<!--        <img [src]="langSelected === ELang.FR ? './assets/flag/fr.svg' :'./assets/flag/en.svg'" alt="">-->
<!--      </div>-->
<!--      <ion-icon (click)="logout()"-->
<!--                class=" cursor link bottom-link" ioniconRemoveTitle name="log-out-outline"></ion-icon>-->
<!--    </div>-->
<!--  </ul>-->
<!--  // SECOND SIDEBAR-->



<!--  -->
<!--  <ul-->
<!--    class="layout-menu w-full col-auto py-3"-->
<!--    role="menu">-->
<!--    <li-->
<!--      class="layout-menuitem-category"-->
<!--      role="none"-->
<!--    >-->
<!--      <div [attr.aria-label]="''" class="pl-3 layout-menuitem-root-text">-->
<!--        {{ selectedMenu!.label | translate}}-->
<!--      </div>-->
<!--      <ul role="menu">-->
<!--        <li role="none">-->
<!--          <a-->
<!--            *ngFor="let item of selectedMenu?.links; let index = index"-->
<!--            [attr.aria-label]="item.label"-->
<!--            [attr.tabindex]="0"-->
<!--            [routerLink]="item.link"-->
<!--            class="px-2"-->
<!--            pRipple-->
<!--            role="menuitem"-->
<!--            routerLinkActive="active"-->
<!--          >-->
<!--            <ion-icon class="layout-menuitem-icon" [name]="item.icon"></ion-icon>-->
<!--            <span>{{ item.label | translate }}</span>-->
<!--          </a>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </li>-->
<!--  </ul>-->


</div>
