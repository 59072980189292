<div [class.dashboard]="dashboard" class="lang-container">
  <div *ngFor="let category of languages" class="field-checkbox">
    <div (click)="selectLang(category.key)" class="lang">
      <img alt="" src="{{ category.flag}}">
      <span class="label">{{category.name}}</span>
    </div>
  </div>


</div>
