import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidebarComponent } from '@app/shared/components/sidebar/sidebar.component';
import { SharedModule } from '@app/shared/shared.module';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BrowserModule} from "@angular/platform-browser";

@NgModule({
  declarations: [SidebarComponent],
  imports: [CommonModule, SharedModule],
  exports: [SidebarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SidebarModule { }
