<div
  [ngClass]="{
    'layout-overlay': isOverlay(),
    'layout-static': isStatic(),
    'layout-theme-light': config.dark,
    'layout-theme-dark': !config.dark,
    'layout-overlay-sidebar-active': overlayMenuActive,
    'layout-static-sidebar-inactive': staticMenuInactive,
    'layout-mobile-sidebar-active': menuActiveMobile,
    'p-ripple-disabled': !config.ripple,
    'p-input-filled': config.inputStyle === 'filled'
  }"
  class="layout-wrapper"
>
  <!--  <app-header></app-header>-->

  <div class="layout-topbar">
    <app-summit-logo class="layout-topbar-logo" [service]="'ico'"></app-summit-logo>

    <a class="p-link layout-menu-button layout-topbar-button" (click)="toggleMenu()">
      <i class="pi pi-bars"></i>
    </a>

    <!--    <div class="flex align-items-center layout-topbar-menu-button"-->
    <!--         *ngIf="(user$ | async) as user"-->
    <!--    >-->
    <!--      <a class="p-link layout-topbar-button">-->
    <!--        <img height="32px" width="32px" [src]="!!user.avatar ? user.avatar[0] : './assets/icon/user.svg'" alt=""-->
    <!--             class="border-round-full">-->
    <!--      </a>-->
    <!--    </div>-->
  </div>


  <div class="layout-sidebar" *ngIf="isDesktop() ? true :  menuActiveMobile">

    <div class="layout-menu-container flex">
      <ul
        class="layout-menu flex flex-column justify-content-between  directory-list"
        role="menu">
        <div class="directory-list-inside mt-3">
          <div
            *ngFor="let menu of menus"
            class="directoryButton"
            [class.medias]="menu.medias"
            (click)="selectMenu(menu)"
            [class.active]="menu.active"
          >
            <app-summit-logo *ngIf="!menu.medias" [hover]="true" [active]="menu.active"
                             [service]="menu.slug"></app-summit-logo>
            <img
              [ngClass]="{ active: menu.active, rotated: menu.rotated }"
              [ngStyle]="{ color: menu.color }"
              *ngIf="menu.medias"
              class="svg-icon medias"
              src="/assets/icon/play.svg"/>
          </div>
        </div>
        <!--    // BOTOM LINK-->
        <div class="flex flex-column justify-content-center align-items-center">
          <div (click)="openLanguageModal()" class="mt-2 cursor">
            <img [src]="langSelected === ELang.FR ? './assets/flag/fr.svg' :'./assets/flag/en.svg'" alt="">
          </div>

          <div
            (click)="goToMessenger()"
            class="mt-2 cursor relative"
          >
            <span *ngIf="hasUnread$ | async" class="unread">{{ hasUnread$ | async }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
              <path
                d="M8.6665 3.83333C8.6665 3.21449 8.91234 2.621 9.34992 2.18342C9.78751 1.74583 10.381 1.5 10.9998 1.5C11.6187 1.5 12.2122 1.74583 12.6498 2.18342C13.0873 2.621 13.3332 3.21449 13.3332 3.83333C14.673 4.46687 15.8151 5.45305 16.6372 6.68618C17.4593 7.91932 17.9304 9.35292 17.9998 10.8333V14.3333C18.0876 15.0587 18.3445 15.7532 18.7498 16.3611C19.1551 16.9691 19.6974 17.4733 20.3332 17.8333H1.6665C2.30227 17.4733 2.84461 16.9691 3.24989 16.3611C3.65516 15.7532 3.91204 15.0587 3.99984 14.3333V10.8333C4.06932 9.35292 4.54036 7.91932 5.36245 6.68618C6.18454 5.45305 7.32669 4.46687 8.6665 3.83333"
                stroke="#1A202C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M7.5 17.832V18.9987C7.5 19.927 7.86875 20.8172 8.52513 21.4736C9.1815 22.1299 10.0717 22.4987 11 22.4987C11.9283 22.4987 12.8185 22.1299 13.4749 21.4736C14.1313 20.8172 14.5 19.927 14.5 18.9987V17.832"
                stroke="#1A202C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <ion-icon (click)="logout()"
                    class=" cursor link bottom-link mt-2" ioniconRemoveTitle name="log-out-outline"></ion-icon>
        </div>


      </ul>

      <ul
        class="layout-menu w-full col-auto py-3"
        role="menu">
        <li
          class="layout-menuitem-category"
          role="none"
        >
          <div [attr.aria-label]="''" class="pl-3 layout-menuitem-root-text">
            {{ selectedMenu!.label | translate }}
          </div>
          <ul role="menu">
            <li role="none">
              <a
                (click)="menuActiveMobile = false"
                *ngFor="let item of selectedMenu?.links; let index = index"
                [attr.aria-label]="item.label"
                [attr.tabindex]="0"
                [routerLink]="item.link"
                class="px-2"
                pRipple
                role="menuitem"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
              >
                <ng-container *ngIf="item.label === 'menu.mining.parc'">
                  <svg id="Layer_1" enable-background="new 0 0 512 512" height="21" viewBox="0 0 512 512" width="21"
                       xmlns="http://www.w3.org/2000/svg" fill="#495057">
                    <path
                      d="m494.057 262.292c-15.895-38.805-39.042-72.084-56.969-92.342l3.521-3.521c13.65-13.652 13.65-35.865-.001-49.517l-45.52-45.519c-6.613-6.613-15.406-10.255-24.759-10.255s-18.146 3.642-24.759 10.255l-3.52 3.52c-20.257-17.927-53.537-41.075-92.342-56.97-51.952-21.279-99.085-23.646-136.3-6.845-9.378 4.233-14.593 13.907-12.979 24.071 1.62 10.201 9.605 17.81 19.87 18.934 64.666 7.084 113.485 44.961 156.248 86.607-4.998 6.198-7.718 13.851-7.718 21.925 0 3.692.576 7.293 1.669 10.71l-260.019 250.067c-6.66 6.389-10.381 15.048-10.478 24.382-.097 9.372 3.636 18.535 10.24 25.139l28.825 28.827c6.521 6.52 15.533 10.24 24.78 10.24.117 0 .236 0 .354-.001 9.334-.096 17.994-3.815 24.374-10.462l250.079-260.035c3.417 1.094 7.019 1.669 10.71 1.669 8.074 0 15.728-2.72 21.925-7.717 41.646 42.762 79.523 91.582 86.607 156.246 1.124 10.265 8.732 18.251 18.933 19.872 10.013 1.588 19.905-3.748 24.072-12.979 16.802-37.217 14.436-84.348-6.843-136.301zm-256.478-214.736c34.373 14.079 63.7 34.224 81.812 50.017l-20.368 20.368c-35.48-34.522-77.642-68.885-130.151-85.936 24.801.219 49.463 7.669 68.707 15.551zm-172.079 431.809c-1.007 1.047-2.859.719-3.806-.233 0 0-28.825-28.827-28.825-28.827-.955-.955-1.274-2.807-.223-3.815l257.7-247.834 22.998 22.998c.001 0-247.743 257.606-247.844 257.711zm281.732-269.077s-45.52-45.521-45.52-45.521c-1.143-1.143-1.135-3.126 0-4.262 0 0 66.484-66.484 66.485-66.485 1.16-1.16 3.11-1.153 4.263 0l45.52 45.519c1.176 1.175 1.176 3.088.001 4.263 0 0-66.486 66.485-66.486 66.485-1.229 1.197-2.948 1.354-4.263.001zm46.827 2.691 20.369-20.369c15.792 18.111 35.938 47.439 50.017 81.811 7.882 19.245 15.332 43.905 15.55 68.707-17.052-52.508-51.414-94.669-85.936-130.149z"/>
                  </svg>
                </ng-container>
                <ion-icon *ngIf="item.label !== 'menu.mining.parc'" class="layout-menuitem-icon" [name]="item.icon"></ion-icon>
                <span class="flex-1">{{ item.label | translate }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!--    <app-sidebar></app-sidebar>-->

  </div>


  <ng-container *ngIf="langModal">
    <app-lang (close)="closeLangModal()"></app-lang>
  </ng-container>
  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
  </div>

  <ng-container *ngIf="menuActiveMobile">
    <div class="layout-mask p-component-overlay"></div>
  </ng-container>

</div>


