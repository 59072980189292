import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "@environments/environment";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private url = `${environment.nestApiUrl}/api/sgp/files/batch`;

  constructor(
    private http: HttpClient
  ) {
  }

  async saveFile(currentFiles: File[]) {
    const formData = new FormData()
    let i = 0;
    for (const file of currentFiles) {
      formData.append(`file-${i}`, file);
      i++
    }
    return firstValueFrom(this.http.post(
      this.url,
      formData,
    ));
  }

  saveFileObservable(currentFiles: File[]) {
    const formData = new FormData()
    let i = 0;
    for (const file of currentFiles) {
      formData.append(`file-${i}`, file);
      i++
    }
    return this.http.post(
      this.url,
      formData,
      {observe: 'events', reportProgress: true}
    )
  }
}
