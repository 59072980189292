import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subject} from 'rxjs';
import {loadMyUser, Logout} from "@shared/stores/auth/auth.actions";
import {EToastSeverity, ETwoFaType, ITwoFaConfig} from '@models/dist';
import {ReactiveLoaderService, ToastService, TwoFaService} from "@shared/services";
import {Router} from "@angular/router";
import {AuthService} from "@shared/services/auth.service";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-twofa-generic',
  templateUrl: './twofa-generic.modal.component.html',
  styleUrls: ['twofa.scss'],
})
export class TwoFaGenericModalComponent implements OnDestroy, OnInit {

  type?: ETwoFaType;

  ETwoFaType = ETwoFaType;

  submitButton = '';
  title = '';
  subtitle = '';
  codeLength = 6;
  focusField = -1;

  step = 0;

  timeLeft = '';
  remainTime = 60000;
  countdownInterval: any;

  displayHotp = true;
  displayTotp = true;

  hotp = "";
  totp = "";

  oldPassword = '';
  newPassword = '';

  twoFaConfig: ITwoFaConfig | null = null;

  private subscription = new Subject<void>();

  constructor(
    public dialogService: DialogService,
    public twoFaService: TwoFaService,
    public toastService: ToastService,
    public authService: AuthService,
    private cd: ChangeDetectorRef,
    public config: DynamicDialogConfig,
    public router: Router,
    public ref: DynamicDialogRef,
    private store: Store,
    private reactiveLoader: ReactiveLoaderService,
    private translate: TranslateService,
  ) {
  }

  async ngOnInit() {
    this.type = this.config?.data?.type;

    switch (this.type) {
      case ETwoFaType.SIGNIN:
        this.title = '2FA.signInModalTitle';
        this.subtitle = '2FA.signInModalText';
        this.submitButton = 'Common.validate';
        this.displayHotp = false;
        this.focusField = 0;
        break;
    }
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  enableSubmit(): boolean {
    if (this.type === ETwoFaType.ACTIVATE && this.step < 2) {
      return true
    }
    if (this.displayTotp && this.totp?.length !== this.codeLength) {
      return false;
    }
    if (this.displayHotp && this.hotp?.length !== this.codeLength) {
      return false;
    }
    return true;
  }

  onHotpChanged(event: any) {
    this.hotp = event;
  }

  onTotpChanged(event: any) {
    this.totp = event;
  }

  copyCodeToClipboard() {
    if (this.twoFaConfig?.secret) {
      navigator.clipboard.writeText(this.twoFaConfig.secret);
    }
  }


  onCodeComplete() {
    if (this.displayTotp && this.displayHotp) {
      if (this.totp?.length === this.codeLength && this.hotp?.length === this.codeLength) {
        this.submit();
      }
    } else if (this.displayHotp) {
      if (this.hotp?.length === this.codeLength) {
        this.submit();
      }
    } else if (this.displayTotp) {
      if (this.totp?.length === this.codeLength) {
        this.submit();
      }
    }
  }

  submit() {
    if (this.type === ETwoFaType.SIGNIN) {
      this.signIn(this.totp);
    }
  }

  close(data?: any) {
    if (this.type === ETwoFaType.SIGNIN) {
      this.store.dispatch(new Logout());
    }
    this.ref.close(data);
  }

  private async signIn(totp: string) {
    try {
      const res = await this.twoFaService.signInTwoFa(totp);
      this.store.dispatch(new loadMyUser());
      this.toastService.addToast(EToastSeverity.success, "2FA.toast.successSigninToastTitle",
        "2FA.toast.successSigninToastMsg");
      this.ref.close(true);
    } catch (error) {
      this.store.dispatch(new Logout());
      this.manageError(error);
      this.toastService.addToast(EToastSeverity.error, "2FA.toast.errorSigninToastTitle",
        "2FA.toast.errorSigninToastMsg");
      this.close();
      this.reactiveLoader.setReactiveButtonState(
        'summit-login',
        reactiveButtonState.error
      );
    }
  }

  private manageError(error: any) {
    if (error?.error?.code?.includes('totp')) {
      this.totp = '';
    }
    if (error?.error?.code?.includes('hotp')) {
      this.hotp = '';
    }
  }
}
