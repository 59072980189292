import {State} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {HttpGravityGateway} from "@gravity/shared/adapters/http-gravity.gateway";
import {HttpMiningGateway} from "@mining/shared/adapters/http-mining.gateway";

export interface IMiningState {
  data: any;
}

@State<IMiningState>({
  name: 'MiningState',
  defaults: {
    data: null
  }
})

@Injectable()
export class MiningState {

  constructor(
    private miningGateway: HttpMiningGateway
  ) {
  }
}
