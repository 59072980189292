import {Component} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {MenuItem} from 'primeng/api';
import {Observable} from 'rxjs';
import {AuthState} from '@shared/stores/auth/auth.state';
import {Logout} from '@shared/stores/auth/auth.actions';
import {IUser} from "@models/dist";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {Location} from "@angular/common";
import {LayoutComponent} from "@app/admin/components/layout/layout.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  userPanel = false;
  items: MenuItem[] = [];
  @Select(AuthState.getUser) user$?: Observable<IUser>;
  public name = "";
  public service: any = 'ico';

  constructor(
    private location: Location,
    public appMain: LayoutComponent, private store: Store, private router: Router) {
  }

  ngOnInit(): void {
    if (this.router.url.includes('research')) {
      this.service = 'research';
    } else {
      this.service = 'ico';
    }
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart || event instanceof NavigationEnd) {
          console.log(event.url, event.url.includes("research"), '--');
          if (event.url.includes("research")) {
            this.service = 'research';
          } else {
            this.service = 'ico';
          }
        }
      }
    )
  }

  async logout() {
    this.store.dispatch(new Logout());
  }

  displayUserPanel() {

    // const body = document.getElementsByTagName('body')[0];
    // if (this.userPanel) {
    //   body.classList.add('no-scroll');
    // } else {
    //   body.classList.remove('no-scroll');
    // }
  }

// getName() {
//
// }


  handleUserPanel() {
    this.userPanel = !this.userPanel
    if (this.userPanel) {
      const context = this.router.url.includes('ico') ? 'ico' : (this.router.url.includes('profile') ? 'profile' : 'ico');
      this.router.navigateByUrl(`/${context}/user-panel`)
    } else {
      this.location.back();
    }
  }
}
