<router-outlet></router-outlet>
<p-toast position="top-right">
  <ng-template let-message pTemplate="message">

    <div class="p-toast-message-text cursor " style="" (click)="goTo(message.id)">
      <div class="p-toast-summary ">{{ message.summary }}</div>
      <div class="p-toast-detail " [innerHTML]="message.detail | safeHtml"></div>
    </div>

  </ng-template>
</p-toast>
<!--<p-progressSpinner *ngIf="displaySpinner" mode="indeterminate"></p-progressSpinner>-->
<div *ngIf="displaySpinner" class="loader">
  <img class="loader-img" src="../assets/loader/spincolored.svg"/>
</div>
