import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from '@environments/environment';
import {firstValueFrom} from 'rxjs';
import {ITransactionAdmin} from '@models/dist';

const BASE_TRANSACTION = `${environment.nestApiUrl}/api/sgp/admin/transactions`;

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) {
  }

  async createAdminTransaction(transaction: ITransactionAdmin) {
    return firstValueFrom(this.http.post(`${BASE_TRANSACTION}/`, transaction));
  }
  async createAdminGenericTransaction(data:any){
    return firstValueFrom(this.http.post(`${BASE_TRANSACTION}/generic`, data));

  }
}
