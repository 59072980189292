import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {PrimeNGConfig} from 'primeng/api';
import {Subscription} from 'rxjs';
import {DialogService} from 'primeng/dynamicdialog';
import {NavService, ToastService, UserService, UtilsService} from "@app/shared/services";
import {Store} from '@ngxs/store';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {STORAGE_DARK_MODE, STORAGE_LANG} from "@app/app.declaration";
import {loadMyUser} from "@shared/stores/auth/auth.actions";
import {ELang, EToastSeverity} from '@models/dist';
import {getAuth, onAuthStateChanged, User} from "firebase/auth";
import {firebaseApp} from "@app/app.module";
import {environment} from "@environments/environment";
import {WebSocketService} from "@app/admin/services/websocket.service";
import LogRocket from 'logrocket';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogService]
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'summit-admin';
  displaySpinner = false;
  navSubscriber: Subscription;
  firebaseUser: User | null = null;

  // requestTwoFaSignIn: Subscription;

  constructor(private toastService: ToastService,
              private zone: NgZone,
              private router: Router,
              private navService: NavService,
              private userService: UserService,
              private store: Store,
              private utilsService: UtilsService,
              private websocketService: WebSocketService,
              private config: PrimeNGConfig,
              private translate: TranslateService,
              private dialogService: DialogService) {

    this.toastService.spinnerUpdate$.subscribe((status : any) => {
      this.displaySpinner = !!status;
    });

    if (environment.production) {
      LogRocket.init('beuqrs/summt-inside');
    }

    const favIcon: any = document.querySelector('#favicon');
    if (!!favIcon) {
      favIcon.href = `assets/favicon/Inside-${environment.status}.ico`;
    }


    this.navSubscriber = this.navService.nav$.subscribe(route => this.zone.run(() => router.navigate(route)));
    // this.requestTwoFaSignIn = this.toastService.twoFaSignInRequest$.subscribe((redirect: TwoFaSignInRedirect) => {
    //   const twoFaModal = this.dialogService.open(TwoFaGenericModalComponent, {
    //     showHeader: false,
    //     contentStyle: {'max-width': '450px', 'border-radius': '20px'},
    //     baseZIndex: 10000,
    //     data: {type: ETwoFaType.SIGNIN}
    //   });
    //
    //   twoFaModal.onClose.subscribe((res: any) => {
    //       if (!res) {
    //         return;
    //       }
    //       if (redirect === TwoFaSignInRedirect.login) {
    //         this.store.dispatch(new LoadUserAfterTwoFa());
    //       } else return;
    //     }
    //   );
    // });

  }


  ngOnInit(): void {
    this.config.ripple = true;
    this.store.dispatch(new loadMyUser());
    this.initializeFirebase();
    this.initTranslation();
    this.setDarkMode();
    this.initWebsocket()
  }


  ngOnDestroy(): void {
    this.navSubscriber.unsubscribe();
  }

  private async initTranslation() {
    this.translate.setDefaultLang(ELang.EN);
    let language = this.translate.getBrowserLang()?.toLowerCase();
    const value = localStorage.getItem(STORAGE_LANG);
    if (value) {
      language = value.toLowerCase();
    }
    if (language) {
      this.translate.use(language);
    }
    if (language === ELang.FR) {
      registerLocaleData(localeFr, 'fr');
    }
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.translate.onLangChange.subscribe((lang: { lang: string }) => {
      const selectedLang = lang?.lang || ELang.EN;
      localStorage.setItem(STORAGE_LANG, selectedLang);
      this.updateLang(selectedLang as ELang);
    });
  }


  initWebsocket(){
    this.websocketService
      .newMessage()
      .subscribe(async (data: any) => {
        console.log("Receiving websocket message" + data);
        const {name, summary, id} = data;
        this.toastService.addToast(
          EToastSeverity.success, this.translate.instant('newMsg', {name}), `${summary}${summary.length === 120 ? '...':''}`,id
        )
        this.store.dispatch(new loadMyUser())
      });
  }
  private setDarkMode() {
    const dark = localStorage.getItem(STORAGE_DARK_MODE);
    this.utilsService.toggleDark(!!dark);
  }

  private async updateLang(lang: ELang) {
    if (!this.firebaseUser?.uid) {
      return;
    }
    try {
      await this.userService.updateLang(lang);
    } catch (e) {
      console.error(e);
    }
    this.store.dispatch(new loadMyUser());
  }


  private initializeFirebase() {
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, async user => {
      this.firebaseUser = user;
    });
  }

  goTo(id:string) {
    console.log(id);
    this.router.navigateByUrl(`/admin/inside/messenger?conversation=${id}`)
  }
}
