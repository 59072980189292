import {Component, OnInit} from '@angular/core';
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {Router} from "@angular/router";
import {STORAGE_WELCOME} from "@app/app.declaration";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(
    public ref: DynamicDialogRef,
    private router: Router) {
  }

  ngOnInit(): void {
  }

  done() {
    localStorage.removeItem(STORAGE_WELCOME);
    this.router.navigate(['./', 'ico', 'dashboard'], {queryParams: {welcome: true}})
    this.ref.close();
  }

}
