import {Injectable} from "@angular/core";
import {filter, map, Subject} from "rxjs";

export class EventData {
  public name: string;
  public data: any;

  constructor(name: string, data: any) {
    this.name = name;
    this.data = data;
  }


}

@Injectable({
  providedIn: 'root'
})
export class LogoutBusService {
  private subject$ = new Subject();

  constructor() {
  }

  emit(event: EventData) {
    this.subject$.next(event)
  }

  on(eventName: string, action: any) {
    return this.subject$.pipe(
      filter((event: any) => {
        return event.name === eventName
      }),
      map((event: any) => event.data)
    ).subscribe(action);
  }

}
