import {LocalizedDatePipe} from "./localized-date.pipe";
import {FixedPipe} from "@shared/pipes/fixed.pipe";
import {CustomNumberPipe} from "@shared/pipes/customNumber.pipe";
import {CountryPipe} from "@shared/pipes/country.pipe";
import {CapitalizePipe} from "@shared/pipes/capitalize.pipe";
import {AddressPipe} from "@shared/pipes/address.pipe";
import {TxPipe} from "@shared/pipes/tx.pipe";
import {SafePipe} from "@shared/pipes/safe.pipe";
import {PhaseNamePipe} from "@shared/pipes/phaseName.pipe";
import {TranslateStaticPipe} from "@shared/pipes/translateStatic.pipe";
import {TranslateFilePipe} from "@shared/pipes/translateFile.pipe";
import {HighlightPipe} from "@shared/pipes/highlight.pipe";
import {TranslateCoverPipe} from "@shared/pipes/translateCover.pipe";
import {MediaPipe} from "@shared/pipes/media.pipe";
import {SafeHtmlPipe} from "@shared/pipes/safeHtml.pipe";
import {TranslateObjectPipe} from "@shared/pipes/translateObject.pipe";

export {
    LocalizedDatePipe,
    FixedPipe,
    CustomNumberPipe,
    CountryPipe,
    CapitalizePipe,
    AddressPipe,
    TxPipe,
    SafePipe,
    SafeHtmlPipe,
    PhaseNamePipe,
    TranslateStaticPipe,
    TranslateFilePipe,
    HighlightPipe,
    TranslateCoverPipe,
    MediaPipe,
    TranslateObjectPipe,
};

export const PIPES = [
    LocalizedDatePipe,
    FixedPipe,
    CustomNumberPipe,
    CountryPipe,
    CapitalizePipe,
    AddressPipe,
    TxPipe,
    SafePipe,
    SafeHtmlPipe,
    PhaseNamePipe,
    TranslateStaticPipe,
    TranslateFilePipe,
    HighlightPipe,
    TranslateCoverPipe,
    MediaPipe,
    TranslateObjectPipe,
];
