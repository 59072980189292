import {Pipe, PipeTransform} from "@angular/core";
import {MiscHelpers} from "@shared/helpers/misc.helpers";

@Pipe({
  name: 'CapitalizePipe'
})
export class CapitalizePipe implements PipeTransform {
  transform(value: any): any {
    return MiscHelpers.capitalizeFirstLetter(value);
  }
}
