import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ELang} from '@models/dist';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss']
})
export class LangComponent implements OnInit, OnDestroy {

  @Output() close = new EventEmitter();

  public open = false;

  @Input() dashboard = false;
  languages: any[] = [
    {name: 'Français', key: ELang.FR, flag: "/assets/flag/fr.svg"},
    {name: 'English', key: ELang.EN, flag: "/assets/flag/en.svg"}];

  constructor(
    private elementRef: ElementRef,
    private translate: TranslateService,
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: any) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.open) {
      this.open = false;
      this.close.emit();
      return;
    }
    this.open = !this.open;
    return;
  }

  ngOnInit(): void {
  }

  selectLang(lang: ELang) {
    try {
      this.translate.use(lang);
      this.close.emit();
    } catch (e) {
      console.log(e);
    }

  }

  ngOnDestroy(): void {
    this.open = false;
  }

}
