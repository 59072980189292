<div class="modal">
  <div class=" flex flex-column align-items-center">
    <span class="summit-gradient title">{{ 'Common.logout' | translate}}</span>
    <span class="content" [innerHTML]="'Common.confirmLogout' | translate"></span>
    <div class="flex align-items-center w-full">
      <app-ico-btn (click)="close()" class="flex-1 mr-5" [outline]="true" [text]="'cancel' | translate"></app-ico-btn>
      <app-ico-btn (click)="logout()" class="flex-1"[outline]="false" [text]="'Common.logout' | translate"></app-ico-btn>
    </div>
  </div>
</div>
