import {Component, Input, OnInit} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {AuthState} from "@shared/stores/auth/auth.state";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Logout} from "@shared/stores/auth/auth.actions";
import {ELang, IUser} from "@models/dist";
import {LangBusService} from "@shared/services";
import {EventData} from "@shared/services/langBus.service";
import {LayoutComponent} from '@app/admin/components/layout/layout.component';
import {animate, state, style, transition, trigger,} from '@angular/animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  // animations: [
  //   trigger('children', [
  //     state(
  //       'void',
  //       style({
  //         height: '0px',
  //       })
  //     ),
  //     state(
  //       'hiddenAnimated',
  //       style({
  //         height: '0px',
  //       })
  //     ),
  //     state(
  //       'visibleAnimated',
  //       style({
  //         height: '*',
  //       })
  //     ),
  //     transition(
  //       'visibleAnimated => hiddenAnimated',
  //       animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
  //     ),
  //     transition(
  //       'hiddenAnimated => visibleAnimated',
  //       animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
  //     ),
  //     transition(
  //       'void => visibleAnimated, visibleAnimated => void',
  //       animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
  //     ),
  //   ]),
  // ],
})
export class SidebarComponent implements OnInit {
  // darkMode = false;
  // langModal = false;
  // @Input() menuActiveMobile = false;
  //
  // public selectedKeys = 'admin';
  //
  // languages: any[] = [
  //   {name: 'Français', key: ELang.FR, flag: "/assets/flag/fr.svg"},
  //   {name: 'English', key: ELang.EN, flag: "/assets/flag/en.svg"}];

  public menus = [
    {
      slug: 'admin',
      background: '#1A202C',
      label: 'menu.admin.title',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      rotated: false,
      active: true,
      links: [
        {
          label: 'menu.admin.dashboard',
          icon: 'home-outline',
          link: '/admin/inside/dashboard',
          display: false,
        },
        {
          label: 'menu.admin.users',
          icon: 'person-outline',
          link: '/admin/inside/users',
          display: false,
        },
        {
          label: 'menu.admin.transactions',
          icon: 'book-outline',
          link: '/admin/inside/transactions',
          display: false,
        },
        {
          label: 'menu.admin.medias',
          icon: 'document-outline',
          link: '/admin/inside/medias',
          display: false,
        },
        {
          label: 'menu.admin.compliance',
          icon: 'finger-print-outline',
          link: '/admin/inside/compliance',
          display: false,
        },
      ]
    },
    {
      slug: 'ico',
      background: 'linear-gradient(45deg, #00FFFF, #D60580)',
      label: 'menu.ico.title',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      rotated: false,
      active: false,
      links: [
        {
          label: 'menu.admin.round',
          icon: 'cash-outline',
          link: '/admin/ico/rounds',
          display: false,
        },
        {
          label: 'menu.admin.sponsor',
          icon: 'people-outline',
          link: '/admin/ico/sponsor',
          display: false,
        },
        {
          label: 'menu.admin.sponsor-game',
          icon: 'people-outline',
          link: '/admin/ico/sponsor-game',
          display: false,
        },
        {
          label: 'menu.admin.roadmap',
          icon: 'map-outline',
          link: '/admin/ico/roadmap',
          display: false,
        }
      ]
    },
    {
      slug: 'research',
      background: 'linear-gradient(45deg, #6D34DB, #C79CCE)',
      label: 'menu.research.title',
      color: '#FFFFF',
      logo: 'assets/icon/logo.svg',
      rotated: false,
      active: false,
      links: [
        {
          label: 'menu.admin.dashboard',
          icon: 'home-outline',
          link: '/admin/research/dashboard',
          display: false,
        },
        {
          label: 'menu.admin.documents',
          icon: 'document-text-outline',
          link: '/admin/research/documents',
          display: false,
        },
        {
          label: 'menu.admin.projects',
          icon: 'document-text-outline',
          link: '/admin/research/projects',
          display: false,
        },
        {
          label: 'menu.admin.promotions',
          icon: 'document-text-outline',
          link: '/admin/research/promotions',
          display: false,
        },
        {
          label: 'menu.admin.glossaire',
          icon: 'document-text-outline',
          link: '/admin/research/glossaires',
          display: true,
        },
      ]
    }
  ]

  // public links = [
  //   {}
  // ]
  // public selectedMenu = this.menus.find((menu) => menu.slug === this.selectedKeys);
  // ELang = ELang;
  // langSelected: ELang = ELang.EN;
  // modalLang = false;


  constructor(
    // private langBusService: LangBusService,
    // private translate: TranslateService,
    // private store: Store,
    // private router: Router,
    // private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    // this.langSelected = this.translateService.currentLang as ELang;
  }

  //
  // public toggleDark(isDarkMode: boolean) {
  //   this.darkMode = isDarkMode;
  //   // this.utilsService.toggleDark(isDarkMode);
  // }
  //
  // navigateTo(item: any) {
  //   console.log("item =>", item.link)
  //   this.router.navigate([item.link]);
  // }
  //
  // logout() {
  //   this.store.dispatch(new Logout());
  // }
  //
  // selectMenu(menu: any) {
  //   this.selectedKeys = menu.slug;
  //   this.selectedMenu = this.menus.find((menu) => menu.slug === this.selectedKeys);
  //   this.menus.forEach(menu => {
  //     menu.active = false;
  //     // menu.rotated = false;
  //     if (menu.slug === this.selectedKeys) {
  //       menu.active = true;
  //       // menu.rotated = true;
  //       this.navigateTo(menu.links[0])
  //     }
  //   })
  // }
  //
  // toggleLanguageModal() {
  //   this.langSelected = this.translate.currentLang as ELang;
  //   this.modalLang = !this.modalLang
  // }
  //
  // public openLanguageModal() {
  //   console.log("la");
  //   this.langModal = !this.langModal;
  //   this.langBusService.emit(new EventData('open', this.langModal));
  // }
}
