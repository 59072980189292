import {Injectable} from '@angular/core';
import {Firestore} from 'firebase/firestore';
import {Functions} from 'firebase/functions';
import {HttpClient} from "@angular/common/http";
import {getAuth} from "firebase/auth";
import jwt_decode from "jwt-decode";
import {ERoles, ESummitServices} from '@models/dist';

export interface IDecodedBearer {
  user_id: string;
  sub: string;
  email: string;
  name: string
  aud: string;
  r: any; // used for roles
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  db?: Firestore;
  functions?: Functions;

  constructor(private http: HttpClient) {
  }

  async getIdToken(): Promise<any> {
    let token;
    try {
      token = await getAuth()?.currentUser?.getIdToken(true);
    } catch (error) {
      console.error(error);
    }

    return token;
  }

  async getRoles(service: ESummitServices): Promise<ERoles[]> {
    const decode = await this.decodeBearer();
    if (decode?.r && decode.r[service]) {
      return decode.r[service];
    }
    return [];
  }

  async decodeBearer(): Promise<IDecodedBearer | null> {
    const token = await this.getIdToken();
    if (token) {
      return jwt_decode(token) as IDecodedBearer;
    }

    return null;
  }

}
