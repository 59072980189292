import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from 'rxjs';
import {environment} from "../../../environments/environment";
import {STORAGE_DARK_MODE} from "@app/app.declaration";


const UTILS = `${environment.nestApiUrl}/api/sgp/utils`;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient) {
  }

  // Send a new link to verify user email
  async getTermsConditions() {
    return firstValueFrom(this.http.get(`${UTILS}/terms-conditions`, {}));
  }

  async getConvert(currency: string, amount: number) {
    return firstValueFrom(this.http.get(`${UTILS}/convert?currency=${currency}&amount=${amount}`))
  }

  toggleDark(isDarkMode: boolean) {
    if (isDarkMode) {
      localStorage.setItem(STORAGE_DARK_MODE, 'true');
      document.body.classList.add('dark');
    } else {
      localStorage.removeItem(STORAGE_DARK_MODE);
      document.body.classList.remove('dark');
    }
  }

}
