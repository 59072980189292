import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {EToastSeverity} from '@models/dist';
import {ToastBusService} from "@shared/services/toastBus.service";

export interface Toast {
  severity: string;
  title: string;
  message: string;
}

export enum TwoFaSignInRedirect {
  "login",
  "signup",
  "none"
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  private displaySpinner = new BehaviorSubject<boolean>(false);
  spinnerUpdate$ = this.displaySpinner.asObservable();
  private toast = new Subject<Toast>();
  toast$ = this.toast.asObservable();
  private TwoFaSignInRequest = new Subject<TwoFaSignInRedirect>();
  twoFaSignInRequest$ = this.TwoFaSignInRequest.asObservable();

  constructor(
    private translate: TranslateService,
    private toastBusService: ToastBusService) {
  }

  addToast(severity: EToastSeverity, title: string, body: any, id?:string) {
    const message = body?.error?.message || body?.message || body || ' ';
    title = title || ' ';
    this.toastBusService.emit({
      severity: EToastSeverity[severity],
      title: this.translate.instant(title),
      content: this.translate.instant(message),
      id
    });
  }

  requestTwoFaSignIn(redirect: TwoFaSignInRedirect = TwoFaSignInRedirect.none) {
    this.TwoFaSignInRequest.next(redirect);
  }

  presentSpinner() {
    this.displaySpinner.next(true);
  }

  dismissSpinner() {
    this.displaySpinner.next(false);
  }

}
