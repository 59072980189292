import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {ELang, IUserPayload} from '@models/dist';


const BASE_NEST = `${environment.nestApiUrl}/api/sgp`;
const BASE_NEST_USERS = `${BASE_NEST}/users`;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  /**************************************
   * USER
   ***************************************/

  async getUser() {
    return firstValueFrom(this.http.get(`${BASE_NEST_USERS}`));
  }

  async getAdminUnreadMsg(){
    return firstValueFrom(this.http.get(`${BASE_NEST}/admin/conversations/unread`));

  }
  // Send a new link to verify user email
  async sendVerificationEmail(email: string) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/verify-email`, {email}));
  }


  // Perform actions after email verification
  async emailVerifiedByUid(email: string) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/email-verified`, {email}));
  }

  async signin() {
    return firstValueFrom(this.http.get(`${BASE_NEST_USERS}/signin`));
  }

  async createUser(data: IUserPayload) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}`, data))
  }

  async validateReferralCode(code: string) {
    return firstValueFrom(this.http.get(`${BASE_NEST}/referrals/` + code));
  }

  async uploadAvatar(file: any) {
    const formData = new FormData();
    formData.append('avatar', file);
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/avatar`, formData));
  }

  checkUserImported({email}: { email: string }, lang = ELang.FR) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/migrations/users`, {email, lang}));
  }

  async verifyOtp(otp: string, email: string) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/migrations/users/check`, {email, otp}));
  }

  async migrations(email: string, password: any, otp: string) {
    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/migrations`, {email, password, otp}));
  }

  async triggerLogin(email: any) {

    return firstValueFrom(this.http.post(`${BASE_NEST_USERS}/logging`, {email}));

  }

  async updateLang(lang: ELang) {
    return firstValueFrom(this.http.put(`${BASE_NEST_USERS}`, {lang}));
  }

}
