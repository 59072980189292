<div *ngIf="question" class="research-modal">
  <!--close modal button -->
  <button
    (click)="close()"
    class="close"
    icon="pi pi-times"
    pButton
  ></button>
  <div class="modal-header ">
    <span [innerHTML]="question.title | translate" class="title text-center"></span>
  </div>

  <div class="modal-body text-center more-width" [formGroup]="form">
      <span [innerHTML]="question.question | translate" class="description">
      </span>

    <div class="form-group mt-2">
      <input class="input" type="text" formControlName="email" [placeholder]="'email' | translate">
    </div>
  </div>
  <div class="modal-footer">
    <app-btn (click)="close()" [outline]="true" [text]="question.negativeLabel | translate"
             class="flex-1 "></app-btn>
    <app-btn (click)="submit()" [disabled]="form.invalid" [text]="question.positiveLabel | translate"
             class="flex-1  "></app-btn>
  </div>
</div>
