import {NavService} from '@shared/services/navigation.service';
import {ReactiveLoaderService} from '@shared/services/reactive-loader.service';
import {TokenService} from '@shared/services/token-service';
import {UserService} from '@shared/services/user-service';
import {UtilsService} from '@shared/services/utils.service';
import {ConfirmationService} from 'primeng/api';
import {LangBusService} from "@shared/services/langBus.service";
import {ToastService} from "@shared/services/toast.service";
import { TwoFaService } from './2fa.service';
import { TransactionService } from './transaction.service';
import { FileService } from './file.service';
import {InternDialogService} from "@shared/services/dialog.service";
import { DialogService } from 'primeng/dynamicdialog';

export {
  NavService,
  ReactiveLoaderService,
  TokenService,
  UserService,
  ToastService,
  UtilsService,
  ConfirmationService,
  LangBusService,
  TwoFaService,
  TransactionService,
  FileService,
  DialogService,
  InternDialogService,
};

export const SERVICES = [
  NavService,
  ReactiveLoaderService,
  TokenService,
  UserService,
  UtilsService,
  ConfirmationService,
  LangBusService,
  TwoFaService,
  TransactionService,
  FileService,
  DialogService,
  InternDialogService,
];
