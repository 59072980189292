import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ELang, IDocument, IMedia} from "@models/dist";

@Pipe({
  name: 'TranslateCover',
  pure: false
})
export class TranslateCoverPipe implements PipeTransform {
  private lang: ELang = ELang.FR;

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang as ELang;
    this.translateService.onLangChange.subscribe(lang => {
      this.lang = lang.lang as ELang
    });
  }

  transform(object: IMedia): string {
    if (this.lang === ELang.FR){
      return !!object.cover_fr ? object.cover_fr.file : ''
    }
    return !!object.cover_en ? object.cover_en.file : ''


  }
}
