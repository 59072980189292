import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'Address'
})
export class AddressPipe implements PipeTransform {
  transform(address: any): any {
    return address.substring(0, 5) + '....' + address.substring(address.length - 4, address.length)
  }
}
