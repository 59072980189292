import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {PasswordModule} from "primeng/password";
import {CheckboxModule} from "primeng/checkbox";
import {InputTextModule} from "primeng/inputtext";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {ImageModule} from "primeng/image";
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from "primeng/divider";
import {TagModule} from "primeng/tag";
import {CardModule} from 'primeng/card';
import {StepsModule} from 'primeng/steps';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DropdownModule} from 'primeng/dropdown';
import {TabViewModule} from 'primeng/tabview';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {PanelModule} from 'primeng/panel';
import {RadioButtonModule} from "primeng/radiobutton";
import {CodeInputModule} from 'angular-code-input';
import {SERVICES} from "@shared/services";
import {COMPONENTS, InfoComponent} from "@shared/components";
import {PIPES} from "@shared/pipes";
import {DIRECTIVES} from "@shared/directives";
import {SkeletonModule} from 'primeng/skeleton';
import {ChipsModule} from 'primeng/chips';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FileUploadModule} from 'primeng/fileupload';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {SelectButtonModule} from "primeng/selectbutton";
import {WelcomeComponent} from './components/welcome/welcome.component';
import {RippleModule} from "primeng/ripple";
import {LogoutComponent} from './components/logout/logout.component';
import {TwofaTriggerComponent} from './components/twofa-trigger/twofa-trigger.component';
import {BtnComponent} from './components/btn/btn.component';
import {MultiSelectModule} from "primeng/multiselect";
import {TooltipModule} from 'primeng/tooltip';
import { MediaPipe } from './pipes/media.pipe';
import { DeleteUserModalComponent } from './components/delete-user-modal/delete-user-modal.component';
import { ChangeUserEmailComponent } from './components/change-user-email/change-user-email.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    RippleModule,
    TooltipModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    CardModule,
    TranslateModule,
    ChipsModule,
    PasswordModule,
    CheckboxModule,
    InputTextModule,
    CodeInputModule,
    TabViewModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    ButtonModule,
    DynamicDialogModule,
    OverlayPanelModule,
    PanelModule,
    DialogModule,
    DropdownModule,
    ImageModule,
    FileUploadModule,
    StepsModule,
    CalendarModule,
    DividerModule,
    TableModule,
    TagModule,
    RadioButtonModule,
    SkeletonModule,
    AutoCompleteModule,
    RadioButtonModule,
    ClipboardModule,
    SelectButtonModule,
    RippleModule,
    MultiSelectModule,
    CalendarModule,
  ],
  declarations: [
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
    WelcomeComponent,
    LogoutComponent,
    TwofaTriggerComponent,
    BtnComponent,
    MediaPipe,
    DeleteUserModalComponent,
    ChangeUserEmailComponent,
  ],
  providers: [
    ...SERVICES,
    ...PIPES
  ],
  exports: [
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
    CommonModule,
    RouterModule,
    PanelModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    TabViewModule,
    DropdownModule,
    CardModule,
    StepsModule,
    MessagesModule,
    MessageModule,
    DynamicDialogModule,
    PasswordModule,
    CheckboxModule,
    InputTextModule,
    DialogModule,
    ButtonModule,
    CodeInputModule,
    OverlayPanelModule,
    ImageModule,
    DividerModule,
    TagModule,
    RadioButtonModule,
    SkeletonModule,
    InfoComponent,
    MultiSelectModule,
    CalendarModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class SharedModule {
}
