import { Component, OnInit } from '@angular/core';
import {Store} from "@ngxs/store";
import {Logout} from "@shared/stores/auth/auth.actions";
import {LogoutBusService} from "@shared/services/logoutBus.service";
import {EventData} from "@shared/services/langBus.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private store: Store, private logoutBusService: LogoutBusService) { }

  ngOnInit(): void {
  }

  close() {
    this.logoutBusService.emit(new EventData('close', false));
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
