import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ELang} from "@models/dist";

@Pipe({
  name: 'TranslateStatic',
  pure: false
})
export class TranslateStaticPipe implements PipeTransform {
  private lang: ELang = ELang.FR;

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang as ELang;
    this.translateService.onLangChange.subscribe(lang => {
      this.lang = lang.lang as ELang
    });
  }

  transform(name: string | undefined | any): string {
    if (!name) {
      return '';
    }
    if (!name.includes('{') || !name.includes('}')) {
      return name;
    }
    const json = JSON.parse(name);
    return json[this.lang];
  }
}
