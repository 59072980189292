// angular button directive
import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {Subject} from 'rxjs';
import {ReactiveLoaderService} from "@shared/services";
import {reactiveButtonState} from "@shared/services/reactive-loader.service";

@Directive({
  selector: '[rButton]',
})
export class RButtonDirective implements OnInit, OnDestroy {

  @Input() rId: string | undefined;
  @Input() bgColor: string | undefined;
  @Output() callbackSucess = new EventEmitter();
  @Output() callbackError = new EventEmitter();
  label: string | undefined;
  stateChanged$: Subject<reactiveButtonState> | undefined;
  state: reactiveButtonState | undefined;
  element: any;


  constructor(private el: ElementRef, private renderer: Renderer2, private reactiveLoaderService: ReactiveLoaderService) {
  }

  ngOnInit() {
    if (!this.rId) {
      throw new Error('rId is required');
    }
    this.renderer.setAttribute(this.el.nativeElement, 'id', this.rId as string);
    if (this.bgColor) {
      this.renderer.addClass(this.el.nativeElement, this.bgColor as string);
    }
    this.element = this.el.nativeElement.innerHtml;
    this.reactiveLoaderService.addReactiveButton(this.rId as string);
    this.stateChanged$ = this.reactiveLoaderService.getReactiveButtonStateSubject(this.rId as string);
    if (this.stateChanged$) {
      this.stateChanged$.subscribe((state: reactiveButtonState) => {
        if (state === reactiveButtonState.loading && this.state !== reactiveButtonState.loading) {
          this.load();
        } else if (state === reactiveButtonState.success && this.state !== reactiveButtonState.success) {
          this.unload();
          this.success();
        } else if (state === reactiveButtonState.error && this.state !== reactiveButtonState.error) {
          this.unload();
          this.error();
        }
        this.state = state;

      });
    }
  }

  ngOnDestroy() {
    this.reactiveLoaderService.removeReactiveButton(this.rId as string);
    if (this.stateChanged$) {
      this.stateChanged$.unsubscribe();
    }
  }

  success() {
    this.renderer.addClass(this.el.nativeElement, 'p-button-success');
    if (this.bgColor) {
      this.renderer.removeClass(this.el.nativeElement, this.bgColor as string);
    }

    var that = this;
    setTimeout(function () {
        that.renderer.removeClass(that.el.nativeElement, 'p-button-success');
        if (that.bgColor) {
          that.renderer.addClass(that.el.nativeElement, that.bgColor as string);
        }

      }
      , 3000);
  }

  load() {
    this.element = this.el.nativeElement.getElementsByClassName('p-button-label')[0].innerHTML;
    this.el.nativeElement.getElementsByClassName('p-button-label')[0].innerHTML = '<img style="width:30px" class="p-button-spinner" src="assets/loader/loadbutton.svg" />';
    this.el.nativeElement.getElementsByClassName('p-button-icon') && Array.from(this.el.nativeElement.getElementsByClassName('p-button-icon')).forEach((el: any) => {
      el.style.display = 'none';
    });
    this.el.nativeElement.style.pointerEvents = 'none';
    this.el.nativeElement.style.cursor = 'default';
  }

  unload() {
    this.el.nativeElement.getElementsByClassName('p-button-label')[0].innerHTML = this.element;
    this.el.nativeElement.getElementsByClassName('p-button-label')[0].style.width = 'auto';
    this.el.nativeElement.getElementsByClassName('p-button-icon') && Array.from(this.el.nativeElement.getElementsByClassName('p-button-icon')).forEach((el: any) => {
        el.style.display = 'block';
      }
    );
    this.el.nativeElement.style.pointerEvents = 'auto';
    this.el.nativeElement.style.cursor = 'pointer';
  }

  error() {
    this.renderer.addClass(this.el.nativeElement, 'p-button-danger');
    if (this.bgColor) {
      this.renderer.removeClass(this.el.nativeElement, this.bgColor as string);
    }

    var that = this;
    setTimeout(function () {
        that.renderer.removeClass(that.el.nativeElement, 'p-button-danger');
        if (that.bgColor) {
          that.renderer.addClass(that.el.nativeElement, that.bgColor as string);
        }
      }
      , 2500);

  }

  //@HostListener('click', ['$event'])
  //onClick(event: any) {
  //  this.load();
  //  setTimeout(() => {
  //    this.unload();
  //    this.success();
  //  }
  //  , 1000);
  //
  //}
}
