export const environment = {
  production: false,
  emulator: false,
  status: 'qualif',
  //appUrl: "https://summit-group-qualif.web.app",
  appUrl : "https://staging-front-9bc0001c.summit.io",
  //apiUrl: "https://europe-west3-summit-group-qualif.cloudfunctions.net",
  //nestApiUrl: "https://api-dot-summit-group-qualif.ew.r.appspot.com",
  apiUrl: "https://staging-api-9bc0001c.summit.io",
  nestApiUrl: "https://staging-api-9bc0001c.summit.io",  
  // adminURL: "https://summit-inside-qualif.web.app",
  adminURL: "https://staging-admin-9bc0001c.summit.io",
  //websocketApi: "https://api-dot-summit-group-qualif.ew.r.appspot.com",
  websocketApi: "https://staging-api-9bc0001c.summit.io",
  firebase: {
    apiKey: "AIzaSyAfNa8zAN4F8Q7JPrX-74ZngERjsFjEC78",
    authDomain: "summit-group-qualif.firebaseapp.com",
    databaseURL: "https://summit-group-qualif-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "summit-group-qualif",
    storageBucket: "summit-group-qualif.appspot.com",
    messagingSenderId: "686027887735",
    appId: "1:686027887735:web:dd99614ce4560dc51d6272",
    measurementId: "G-EP2PWVT36W"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
