export class GetPriceHistory {
  static readonly type = '[Gravity] Get Price History';

  constructor() {
  }
}

export class SetDateRange{
  static readonly type = '[Gravity] Set Date Range';

  constructor(public payload: Date[]) {
  }

}

export class SetTransactionDateRange{
  static readonly type = '[Gravity] Set Transaction Date Range';

  constructor(public payload: Date[]) {
  }

}

export class SetKpiDateRange{
  static readonly type = '[Gravity] Set Kpi Date Range';

  constructor(public payload: Date[]) {
  }

}

export class SetPrice {
  static readonly type = '[Gravity] Set Price';

  constructor(public payload: number) {
  }
}

export class SetPriceSuccess{
  static readonly type = '[Gravity] Set Price Success';

}

export class GetTransaction {
  static readonly type = '[Gravity] Get Transaction';

  constructor(date?: Date[] | null) {
  }
}

export class GetKpi{
  static readonly type = '[Gravity] Get Kpi';

  constructor(date?: Date[] | null) {
  }
}

export class SetFilter{
  static readonly type = '[Gravity] Set Filter';

  constructor(public payload: {type: 'incoming' | 'outgoing' | null, search: string | null}) {
  }
}
