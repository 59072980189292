import {Injectable, Type} from "@angular/core";
import { PortfoliosDetailsComponent } from "@app/admin/modules/users/components/user/portfolios-details/portfolios-details.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {DeleteUserModalComponent} from "@shared/components/delete-user-modal/delete-user-modal.component";
import {DynamicDialogConfig} from "primeng/dynamicdialog/dynamicdialog-config";

@Injectable({
  providedIn: 'root',
})
export class InternDialogService {

  public ref?: DynamicDialogRef;
  constructor(
    private dialogService: DialogService,
  ) {
  }


  openModal(
    component: Type<any>,
    data: any,
    contentStyle = {
      'width': '100%',
      'min-width': '650px',
      'max-width': '650px',
      'max-height': '800px',
      'border-radius': '20px'
    },
  ) {
    this.ref = this.dialogService.open(component, {
      showHeader: false,
      closeOnEscape: true,
      dismissableMask: true,
      data,
      contentStyle,
      baseZIndex: 10000,
    });
  }
}
