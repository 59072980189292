<div
  [style.height.px]="height"
  [style.width.px]="width"
  [class.hover]="hover"
  [class]="service + ' summit-token-logo'" (click)="goTo()" [class.active]="active">
  <img

    alt=""
    class="svg-icon"
    src="assets/icon/logo.svg"/>
</div>
