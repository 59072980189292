<div *ngIf="question" class="research-modal">
  <!--close modal button -->
  <button
    (click)="close()"
    class="close"
    icon="pi pi-times"
    pButton
  ></button>
  <div class="modal-header ">
    <span [innerHTML]="question.title | translate" class="title text-center"></span>
  </div>

  <div class="modal-body more-width">
      <span [innerHTML]="question.question | translate" class="description">
      </span>
  </div>
  <div class="modal-footer">
    <app-btn (click)="close()" [outline]="true" [text]="question.negativeLabel | translate"
             class="flex-1 "></app-btn>
    <app-btn (click)="submit()" [text]="question.positiveLabel | translate"
             class="flex-1  "></app-btn>
  </div>
</div>
