import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ETransactionCurrency} from '@models/dist';

//  {{1234.56 | customNumber:'2':'0':'€'}}
@Pipe({
  name: 'customNumber',
  pure: false
})
export class CustomNumberPipe implements PipeTransform {

  constructor(
    private translate: TranslateService) {
  }

  transform(value: any, maxDigits: any = 2, minimumDigits: any = 0, currency: string = ''): any {

    if (value === undefined || value === null) {
      value = 0;
    }

    if (!isNaN(value)) {
      value = parseFloat(value);
    } else {
      return value + ' ' + currency;
    }

    let symbol = '';
    if (currency) {
      currency = currency?.toString().toUpperCase();
      if (currency === 'EUR' || currency === 'EURO' || currency === 'EUROS') {
        symbol = '€';
      } else if (currency === 'USD' || currency === 'US_DOLLAR') {
        symbol = '$';
      } else if (currency === ETransactionCurrency.SUMMIT_TOKEN) {
        symbol = '';//this.translate.instant('Common.summittokens');
      } else if (currency === ETransactionCurrency.ETHEREUM) {
        symbol = 'ETH'
      } else if (currency === ETransactionCurrency.SIN) {
        symbol = 'SGK'
      }else {
        symbol = currency;
      }
    }

    const locale = this.translate.currentLang.toLowerCase();
    // fr-FR
    // en-US
    // en
    const formattedNumber = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumDigits,
      maximumFractionDigits: maxDigits,
    }).format(value);

    if (!symbol) {
      return formattedNumber;
    }

    if ((locale === 'en-US' || locale === 'en') && (symbol === '$' || symbol === '€')) {
      return symbol + formattedNumber;
    } else if ((locale === 'en-US' || locale === 'en') && (symbol === '%')) {
      return formattedNumber + symbol;
    } else {
      return formattedNumber + ' ' + symbol;
    }

  }

}
