<div class="errorDisplay">
  <!--close modal button -->
  <button
    class="close"
    icon="pi pi-times"
    pButton
  ></button>
  <div class="modal-header">
    <div [innerHTML]="'welcome-popup.title' | translate" class="title text-center">

    </div>

  </div>
  <div class="modal-body text-center">
    <span [innerHTML]="'welcome-popup.description' | translate" class="description text-center"></span>
  </div>
  <div class="modal-footer">
    <app-ico-btn (click)="done()" [text]="'welcome-popup.done'|translate"></app-ico-btn>
  </div>
</div>
