import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ERoutes} from '@models/dist';
import {AdminGuard} from "@app/guards/admin.guard";
import {LayoutComponent} from "@app/admin/components/layout/layout.component";

const routes: Routes = [
  {
    path: ERoutes.AUTH,
    loadChildren: () => import('@auth/auth.module').then(m => m.AuthModule),
    data: {preload: true}
  },
  {
    path: ERoutes.ADMIN,
    component: LayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: 'inside',
        canActivate: [AdminGuard],

        loadChildren: () => import('@app/admin/admin.module').then((m) => m.AdminModule),
        data: {preload: true},
      },
      {
        path: ERoutes.RESEARCH,
        canActivate: [AdminGuard],

        loadChildren: () => import('@app/research/research.module').then((m) => m.ResearchModule),
        data: {preload: true},
      },
      {
        path: 'mining',
        canActivate: [AdminGuard],
        loadChildren: () => import('@app/mining/mining.module').then((m) => m.MiningModule),
        data: {preload: true},
      },
      {
        path: 'gravity',
        canActivate: [AdminGuard],
        loadChildren: () => import('@app/gravity/gravity.module').then((m) => m.GravityModule),
        data: {preload: true},
      },
      {
        path: 'ico',
        canActivate: [AdminGuard],

        loadChildren: () => import('@app/ico/ico.module').then((m) => m.IcoModule),
        data: {preload: true},
      },
      {
        path: 'markets',
        loadChildren: () => import('@app/markets/markets.module').then((m) => m.MarketsModule),
        data: {preload: true}
      },
      {
        path: 'medias',
        canActivate: [AdminGuard],
        loadChildren: () => import('@app/medias/medias.module').then((m) => m.MediasModule),
        data: {preload: true},
      },
    ]
  },
  {
    path: "**",
    redirectTo: `${ERoutes.AUTH}`,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
