import {IProject} from "summit-group-common";
import {Action, State, StateContext} from "@ngxs/store";
import {inject, Injectable} from "@angular/core";
import {GetProjects} from "@app/research/shared/stores/research.actions";
import {ResearchGateway} from "@app/research/shared/ports/research.gateway";
import {filter, map, tap} from "rxjs";
import {HttpResearchGateway} from "@app/research/shared/adapters/http-research.gateway";

export interface IResearchState {
  projects: IProject[],
}

@State<IResearchState>({
  name: 'ResearchState',
  defaults: {
    projects: []
  }
})
@Injectable()
export class ResearchState {
  researchGateway = inject(HttpResearchGateway);

  @Action(GetProjects)
  getProjects(ctx: StateContext<IResearchState>) {
    return this.researchGateway.getProjects().pipe(
      map((projects) => projects.filter((project) => project.title !== "Liquidités")),
      tap((projects) => ctx.patchState({
        projects
      }))
    )
  }
}
