import {inject, Injectable} from "@angular/core";
import {GravityGateway} from "@gravity/shared/ports/gravity.gateway";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {MiningGateway} from "@mining/shared/ports/mining.gateway";

@Injectable({
  providedIn: 'root'
})
export class HttpMiningGateway implements MiningGateway {
  http = inject(HttpClient);

  url = environment.nestApiUrl + '/api/sgp/admin/gravity'
}
