import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SortEvent} from "primeng/api";

@Component({
  selector: 'app-default-table',
  templateUrl: './default-table.component.html',
  styleUrls: ['./default-table.component.scss']
})
export class DefaultTableComponent implements OnInit {

  @Input() data: any[] = [];
  @Input() header?: { name: string, sortField?: string }[];
  @Input() class?: string;

  @Input() paginator = false;
  @Input() rowsPerPageOptions = [10];
  @Input() rows = 10;
  first = 0;

@Output() pagination = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  logout() {

  }

  onSort(event: SortEvent) {
    const data = this.data;
    const field = event.field!;
    const order = event.order!;

    data.sort((data1, data2) => {
      let value1 = data1[field];
      let value2 = data2[field];
      let result;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (order * result);
    });
  }


  keys(object: any) {
    return Object.keys(object)
  }

}
