import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from '@environments/environment';
import {firstValueFrom} from 'rxjs';

const BASE_SECURITY = `${environment.nestApiUrl}/api/sgp/security`;

@Injectable({
  providedIn: 'root'
})
export class TwoFaService {

  constructor(private http: HttpClient) {
  }

  async signInTwoFa(codeGoogle: string) {
    return firstValueFrom(this.http.post(`${BASE_SECURITY}/mfa-totp/signin`, {"totpToken": codeGoogle}));
  }

  async sendChangeWalletEmail(id: string) {
    return firstValueFrom(this.http.get(`${BASE_SECURITY}/mfa-totp/hotp-change-wallet/admin/${id}`));
  }

  async sendAddWalletEmail(id: string) {
    return firstValueFrom(this.http.get(`${BASE_SECURITY}/mfa-totp/hotp-add-wallet/admin/${id}`));
  }

  async doubleAuthentication(totpToken: string, hotpToken: string) {
    return firstValueFrom(this.http.post(`${BASE_SECURITY}/mfa-totp/double-authentication`,
      {
        hotpToken,
        totpToken
      }));
  }
}
