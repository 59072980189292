import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ELang, IDocument} from "@models/dist";

@Pipe({
  name: 'TranslateFile',
  pure: false
})
export class TranslateFilePipe implements PipeTransform {
  private lang: ELang = ELang.FR;

  constructor(private translateService: TranslateService) {
    this.lang = this.translateService.currentLang as ELang;
    this.translateService.onLangChange.subscribe(lang => {
      this.lang = lang.lang as ELang
    });
  }

  transform(object: IDocument): string {
    if (this.lang === ELang.FR){
      return object['pictureFr']
    }
    return object['pictureEn']

  }
}
