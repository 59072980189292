import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {MessageService} from "primeng/api";
import {EToastSeverity} from '@models/dist';

@Injectable({
  providedIn: 'root'
})
export class ToastBusService {
  private subject$ = new Subject();

  constructor(
    private messageService: MessageService
  ) {
  }

  emit({severity, title, content, id}: { severity: EToastSeverity, title: string, content: string, id:string| undefined }) {
    this.messageService.add({
      severity,
      summary: title,
      detail: content,
      life: 10000,
      id
    })
  }

  on(eventName: string, action: any) {
    return this.subject$.subscribe(action);
  }

}
