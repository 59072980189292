import {RButtonDirective} from "@shared/directives/rButton.directive";
import {TimerButtonDirective} from "./timerButton.directive";
import {IoniconsRemoveTitleDirective} from "@shared/directives/ioniconRemoveTitle.directive";

export {
  RButtonDirective,
  TimerButtonDirective,
  IoniconsRemoveTitleDirective,
};

export const DIRECTIVES = [
  RButtonDirective,
  TimerButtonDirective,
  IoniconsRemoveTitleDirective,
];
