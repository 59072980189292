import {IPriceDTO} from "@gravity/shared/dto/price.dto";
import {HttpClient} from "@angular/common/http";
import {GravityGateway} from "@gravity/shared/ports/gravity.gateway";
import {IKpi, IPrice, ITransactionDetails} from "@gravity/shared/store/gravity.state";
import {inject, Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpGravityGateway implements GravityGateway {
  http = inject(HttpClient);

  url = environment.nestApiUrl + '/api/sgp/admin/gravity'

  constructor() {
  }

  getPriceHistory(range: Date[] | null) {
    return this.http.get<IPrice[]>(`${this.url}/prices${range ? `?from=${range[0].toISOString()}&to=${range[1] ? range[1].toISOString() : ''}` : ''}`)
  }

  setPrice(price: number) {
    return this.http.post(`${this.url}/price`, {price});
  }

  getTransaction(date: Date[] | null): Observable<ITransactionDetails> {
    return this.http.get<ITransactionDetails>(`${this.url}/transactions${date ? `?from=${date[0]}&to=${date[1] ? date[1] : ''}` : ''}`)
  }

  getKpi(date: Date[] | null): Observable<IKpi> {
    return this.http.get<IKpi>(`${this.url}/kpi${date ? `?from=${date[0].toISOString()}&to=${date[1] ? date[1].toISOString() : ''}` : ''}`)

  }
}
