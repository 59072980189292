import {FormControl} from '@angular/forms';

export class PasswordValidator {

  static isValid(control: FormControl) {
    const password = control.value || '';
    let minlength = false;
    let noLowerCase = false;
    let noUpperCase = false;
    let noNumber = false;
    let noSpecialChar = false;

    if (password.length < 8) {
      minlength = true;
    }

    const lowercaseRegex = new RegExp("(?=.*[a-z])");// has at least one lower case letter
    if (!lowercaseRegex.test(password)) {
      noLowerCase = true;
    }

    const uppercaseRegex = new RegExp("(?=.*[A-Z])"); //has at least one upper case letter
    if (!uppercaseRegex.test(password)) {
      noUpperCase = true;
    }

    const numRegex = new RegExp("(?=.*\\d)"); // has at least one number
    if (!numRegex.test(password)) {
      noNumber = true;
    }

    const specialcharRegex = new RegExp("[!@#$%^&*()_,.?\":{}|<>]");
    if (!specialcharRegex.test(password)) {
      noSpecialChar = true;
    }

    if (!minlength && !noLowerCase
      && !noUpperCase
      && !noNumber
      && !noSpecialChar) {

      return null;
    }

    return {
      minlength,
      noLowerCase,
      noUpperCase,
      noNumber,
      noSpecialChar
    };

  }

}
