<div class="layout-topbar">
  <app-summit-logo class="layout-topbar-logo" [service]="service"></app-summit-logo>

  <a class="p-link layout-menu-button layout-topbar-button" (click)="appMain.toggleMenu()">
    <i class="pi pi-bars"></i>
  </a>

  <div class="flex align-items-center layout-topbar-menu-button" *ngIf="user$ | async as user">
    <a class="p-link layout-topbar-button" (click)="handleUserPanel()">
      <img [src]="!!user.avatarUrl ? user.avatarUrl : './assets/icon/user.svg'" alt="">
    </a>
  </div>
</div>
