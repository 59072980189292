import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'FixedPipe'
})
export class FixedPipe implements PipeTransform {
  transform(value: any): string {
    return Number(value).toFixed(2).toString();
  }
}
