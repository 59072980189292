<div
  [ngClass]="{
    'layout-overlay': isOverlay(),
    'layout-static': isStatic(),
    'layout-theme-light': config.dark,
    'layout-theme-dark': !config.dark,
    'layout-overlay-sidebar-active': overlayMenuActive,
    'layout-static-sidebar-inactive': staticMenuInactive,
    'layout-mobile-sidebar-active': menuActiveMobile,
    'p-ripple-disabled': !config.ripple,
    'p-input-filled': config.inputStyle === 'filled'
  }"
  class="layout-wrapper">

  <app-header></app-header>
  <div class="layout-sidebar">
    <app-menu [menuActiveMobile]="menuActiveMobile"></app-menu>
  </div>
  <app-lang (close)="closeLangModal()" *ngIf="langModal"></app-lang>
  <app-logout (close)="closeLogoutModal()" *ngIf="openLogoutModal"></app-logout>

  <div class="layout-main-container">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div *ngIf="menuActiveMobile" class="layout-mask p-component-overlay"></div>
</div>
