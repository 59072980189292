import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export enum reactiveButtonState {
  loading = 'loading',
  success = 'success',
  error = 'error',
  normal = 'normal'
}

export interface reactiveButton {
  id: string;
  state: reactiveButtonState;
  state$: Subject<reactiveButtonState>;
}

@Injectable({
  providedIn: 'root',
})
export class ReactiveLoaderService {
  private static reactiveButtons: reactiveButton[] = [];

  constructor() {
  }

  public addReactiveButton(id: string) {
    ReactiveLoaderService.reactiveButtons.push({
      id,
      state: reactiveButtonState.normal,
      state$: new Subject<reactiveButtonState>()
    });
  }

  public getReactiveButtonStateSubject(id: string) {
    if (!id || !ReactiveLoaderService.reactiveButtons.find(button => button.id === id)) {
      return undefined;
    }
    return ReactiveLoaderService.reactiveButtons.find(button => button.id === id)?.state$;
  }

  public removeReactiveButton(id: string) {
    ReactiveLoaderService.reactiveButtons = ReactiveLoaderService.reactiveButtons.filter((button) => button.id !== id);
  }

  public setReactiveButtonState(id: string, state: reactiveButtonState) {
    const button = ReactiveLoaderService.reactiveButtons.find((button) => button.id === id);
    if (button) {
      button.state = state;
      button.state$.next(state);
    }
  }
}
