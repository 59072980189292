import {ResearchGateway} from "@app/research/shared/ports/research.gateway";
import {Observable} from "rxjs";
import {IProject} from "@models/dist";
import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpResearchGateway implements ResearchGateway {
  http = inject(HttpClient);
  url = `${environment.nestApiUrl}/api/sgp/admin/research/projects`;

  getProjects(): Observable<IProject[]> {
    return this.http.get<IProject[]>(`${this.url}/all`)
  }
}
