import {animate, state, style, transition, trigger,} from '@angular/animations';
import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {Select} from "@ngxs/store";
import {Observable, Subject} from "rxjs";
import {IUser} from '@models/dist';
import {DialogService} from "primeng/dynamicdialog";
import {LangBusService, TokenService} from "@app/shared/services";
import {TranslateService} from "@ngx-translate/core";
import {AuthState} from "@shared/stores/auth/auth.state";
import {EventData} from "@shared/services/langBus.service";
import {LogoutBusService} from "@shared/services/logoutBus.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styles: [],
  animations: [
    trigger('submenu', [
      state(
        'hidden',
        style({
          height: '0px',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition(
        'visible => hidden',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hidden => visible',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  menuMode = 'static';
  config = {
    dark: false,
    inputStyle: 'outlined',
    ripple: true,
    theme: 'lara-light-blue',
  };
  public menuInactiveDesktop: boolean = false;
  public menuActiveMobile: boolean = false;
  public overlayMenuActive: boolean = false;
  public staticMenuInactive: boolean = false;
  public profileActive: boolean = true;
  public topMenuActive: boolean = true;
  public topMenuLeaving: boolean = true;
  public theme: string = "lara-light-blue";
  documentClickListener: any = null;
  documentSwipeListener: any = null;
  documentSwipeMoveListener: any = null;
  menuClick: boolean = false;
  topMenuButtonClick: boolean = false;
  configActive: boolean = false;
  configClick: boolean = false;
  xDown = null;
  yDown = null;
  ref: any;
  public openLogoutModal = false;
  @Select(AuthState.getUser) user$: Observable<IUser> | undefined;
  langModal = false;
  user: IUser | null = null;

  private subscription = new Subject<void>();

  constructor(
    private langBusService: LangBusService,
    private dialogService: DialogService,
    private tokenService: TokenService,
    private translateService: TranslateService,
    private logoutBusService: LogoutBusService,
    public renderer: Renderer2, private primengConfig: PrimeNGConfig) {
  }

  ngOnInit() {
    window.document.body.classList.add('dashboard');
    this.langBusService.on('open', (data: boolean) => {
      this.langModal = true;
    })
    this.langBusService.on('close', (data: boolean) => {
      this.langModal = false;
    })

    this.logoutBusService.on('open', (data: boolean) => {
      this.openLogoutModal = true
    });
    this.logoutBusService.on('close', (data: boolean) => {
      this.openLogoutModal = false
    });

    this.getUser();

    this.primengConfig.ripple = true;
    document.documentElement.style.fontSize = '16px';
  }

  // handleTouchMove(evt: any) {
  //   if (!this.xDown || !this.yDown) {
  //     return;
  //   }
  //   var xUp = evt.touches[0].clientX;
  //   var yUp = evt.touches[0].clientY;
  //   var xDiff = this.xDown - xUp;
  //   var yDiff = this.yDown - yUp;
  //   if (Math.abs(xDiff) > Math.abs(yDiff)) {
  //     if (xDiff < 0 && Math.abs(xDiff) > 5) {
  //       this.toggleMenu(evt);
  //
  //     } else if (Math.abs(xDiff) > 5) {
  //       this.toggleMenu(evt);
  //     }
  //   }
  //   this.xDown = null;
  //   this.yDown = null;
  // }
  //
  // handleTouchStart(evt: any) {
  //   const firstTouch = (evt.touches || evt.originalEvent.touches)[0];
  //   this.xDown = firstTouch.clientX;
  //   this.yDown = firstTouch.clientY;
  // }

  ngAfterViewInit() {
    //document.addEventListener('touchstart', this.handleTouchStart, false);
    //document.addEventListener('touchmove', this.handleTouchMove, false);
    this.documentClickListener = this.renderer.listen(
      'body',
      'click',
      (event) => {
        if (event.clientX < 300) {
          return;
        }
        this.menuActiveMobile = false;
        if (!this.isDesktop()) {
          if (!this.menuClick) {
            this.menuActiveMobile = false;
          }

          if (!this.topMenuButtonClick) {
            this.hideTopMenu();
          }
        } else {
          if (!this.menuClick && this.isOverlay()) {
            this.menuInactiveDesktop = true;
          }
          if (!this.menuClick) {
            this.overlayMenuActive = false;
          }
        }

        if (this.configActive && !this.configClick) {
          this.configActive = false;
        }

        this.configClick = false;
        this.menuClick = false;
        this.topMenuButtonClick = false;
      }
    );
  }

  toggleMenu(event: Event) {
    this.menuClick = true;
    if (this.isDesktop()) {
      if (this.menuMode === 'overlay') {
        if (this.menuActiveMobile === true) {
          this.overlayMenuActive = true;
        }
        this.overlayMenuActive = !this.overlayMenuActive;
        this.menuActiveMobile = false;
      } else if (this.menuMode === 'static') {
        this.staticMenuInactive = !this.staticMenuInactive;
      }
    } else {
      this.menuActiveMobile = !this.menuActiveMobile;
      this.topMenuActive = false;
    }

    event.preventDefault();
  }

  toggleProfile(event: Event) {
    this.profileActive = !this.profileActive;
    event.preventDefault();
  }

  toggleTopMenu(event: Event) {
    this.topMenuButtonClick = true;
    this.menuActiveMobile = false;

    if (this.topMenuActive) {
      this.hideTopMenu();
    } else {
      this.topMenuActive = true;
    }

    event.preventDefault();
  }

  hideTopMenu() {
    this.topMenuLeaving = true;
    setTimeout(() => {
      this.topMenuActive = false;
      this.topMenuLeaving = false;
    }, 1);
  }

  onMenuClick() {
    this.menuClick = true;
  }

  isStatic() {
    return this.menuMode === 'static';
  }

  isOverlay() {
    return this.menuMode === 'overlay';
  }

  isDesktop() {
    return window.innerWidth > 992;
  }

  onSearchClick() {
    this.topMenuButtonClick = true;
  }

  ngOnDestroy() {
    this.subscription.next();
    this.subscription.complete();
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }

  closeLangModal() {
    this.langBusService.emit(new EventData('close', this.langModal));
  }

  closeLogoutModal() {
    this.logoutBusService.emit(new EventData('close', this.langModal));
  }

  private getUser() {
    // this.user$?.pipe(takeUntil(this.subscription)).subscribe(async user => {
    //   this.user = user;
    //   const roles = await this.tokenService.getRoles(ESummitServices.SUMMIT_GROUP);
    //   const welcome = localStorage.getItem(STORAGE_WELCOME);
    // })
  }
}
