import {Component, Input, OnInit} from "@angular/core";
import {Router} from "@angular/router";

@Component({
  selector: 'app-summit-logo',
  templateUrl: './summit-logo.component.html',
  styleUrls: ['./summit-logo.component.scss']
})
export class SummitLogoComponent {
  @Input() service: string | 'ico' | 'research' | 'admin' | 'media' | 'mining' | 'quantum' | 'markets' = 'ico'
  @Input() active: boolean = false;
  @Input() height: number = 45;
  @Input() width: number = 45;
  @Input() hover: boolean =false;

  constructor(
    private router: Router
  ) {

  }

  goTo() {
    if (this.service === 'ico') {
      this.router.navigateByUrl('/ico/dashboard')
      return;
    }

    if (this.service === 'research') {
      this.router.navigateByUrl('/research/dashboard')
      return;
    }


  }
}
