import { Component, OnInit } from '@angular/core';
import {IConfirmationQuestion} from "@models/dist";
import {AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-change-user-email',
  templateUrl: './change-user-email.component.html',
  styleUrls: ['./change-user-email.component.scss']
})
export class ChangeUserEmailComponent implements OnInit {

  public question: IConfirmationQuestion | any = null;

  private sameMail: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    const emailForm = group.get('email')?.value;
    return emailForm === this.email ? null : {notSame: true};
  };

  form = this.fb.group({
    email: this.fb.control("", [Validators.required, Validators.email]),
    newEmail: this.fb.control("", [Validators.required, Validators.email])
  }, {validators: this.sameMail})

  email: string = '';

  constructor(
    public fb: FormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.email = this.config?.data.email;
    this.question = this.config?.data;
    if (!this.question) {
      this.close();
    }
  }




  close() {
    this.ref.close();
  }

  submit() {
    if(this.form.invalid) return;
    this.ref.close(this.form.value.newEmail);
  }

}
