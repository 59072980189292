// angular button directive
import {AfterContentInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2,} from '@angular/core';
import {interval, Subscription} from 'rxjs';

@Directive({
  selector: '[timeButton]',
})
export class TimerButtonDirective implements AfterContentInit, OnDestroy {

  @Input() delay: number | undefined;
  element: any;
  savedElement: any;
  public dateNow = new Date();
  public dDay: any;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  public timerActive = false;
  public timeDifference: number = 0;
  public secondsToDday: number = 0;
  public minutesToDday: number = 0;
  private subscription: Subscription | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterContentInit() {
    this.element = this.el.nativeElement;
    this.savedElement = this.el.nativeElement.innerHtml;
  }

  updateButtonText() {
    this.el.nativeElement.getElementsByClassName('p-button-label')[0].innerHTML = `${String(this.minutesToDday).padStart(2, '0')}:${String(this.secondsToDday).padStart(2, '0')}`;

  }

  disableButton() {
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
  }

  enableButton() {
    this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
  }

  replaceButtonText() {
    this.el.nativeElement.getElementsByClassName('p-button-label')[0].innerHTML = this.savedElement;
  }

  startTimer() {
    this.element = this.el.nativeElement;
    this.savedElement = this.el.nativeElement.getElementsByClassName('p-button-label')[0].innerHTML;
    this.timerActive = true;
    this.dDay = new Date(new Date().setSeconds(this.dateNow.getSeconds() + (this.delay as number)));
    this.disableButton();
    this.subscription = interval(1000).subscribe((x) => {
      this.getTimeDifference();
      if (this.secondsToDday <= 0 && this.minutesToDday <= 0) {
        this.stopTimer();
      } else {
        this.updateButtonText();
      }
    });
  }

  stopTimer() {
    this.timerActive = false;
    this.replaceButtonText();
    this.enableButton();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  @HostListener('click', ['$event'])
  onClick(event: any) {
    this.startTimer();
    if (this.timerActive) {
      event.preventDefault();
    }
  }

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference: any) {
    this.secondsToDday = Math.floor(
      (timeDifference / this.milliSecondsInASecond) % this.SecondsInAMinute
    );
    this.minutesToDday = Math.floor(
      (timeDifference / (this.milliSecondsInASecond * this.minutesInAnHour)) %
      this.SecondsInAMinute
    );
  }
}
