import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  constructor(private socket: Socket) {
  }

  getIncomingMessage(userId: string) {
    console.log("init websocket for message ", userId)
    return this.socket.fromEvent(`message_${userId}`);
  }

  getIncomingConversation(userId: string) {
    return this.socket.fromEvent(`new_conversation_${userId}`);
  }

  newMessage(){

    return this.socket.fromEvent(`new_message_admin`);

  }

}
