import {Action, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {HttpGravityGateway} from "@gravity/shared/adapters/http-gravity.gateway";
import {
  GetKpi,
  GetPriceHistory,
  GetTransaction,
  SetDateRange, SetFilter, SetKpiDateRange,
  SetPrice,
  SetPriceSuccess, SetTransactionDateRange
} from "@gravity/shared/store/gravity.actions";
import {delay, of, startWith, tap} from "rxjs";

export interface IGravityTransaction {
  id: string;
  amount: number
  category: string
  createdBy: string
  currency: string
  direction: string
  created: { _seconds: number };
  email: string
  exchangeRate: number;
  exchangedAmount: number;
  exchangedCurrency: string
  method: string
  note: string
  status: string
  transactionId: string
  type: string
  uid: string

}

export interface IGravityTransactionFilter {
  search: string | null;
  type: 'incoming' | 'outgoing' | null;
}

export interface IPrice {
  price: number;
  date: number;
  delta: number;
  aum: number;
  total: number;
}

export interface ITransactionDetails {
  clients: number;
  SGKEnter: number;
  SGKOut: number;
  transactionsNumber: number;
  EUROEnter: number;
  EUROOut: number;
  incommingTransaction: IGravityTransaction[];
  transactionsOutgoing: IGravityTransaction[];
  transactions: IGravityTransaction[],
  filter: IGravityTransactionFilter
}

export interface IKpi {
  overview: {
    clients: number;
    totalInvestEURO: number;
    totalSGK: number;
    totalEURO: number;
  },
  details: {
    price: number;
    EUROEnter: number;
    EUROOut: number;
  }
}


export interface IGravityState {
  priceHistory: IPrice[],
  dateRange: Date[];
  transactionDateRange: Date[];
  kpiDateRange: Date[];
  kpi: IKpi
  transactionsDetails: ITransactionDetails,
  transactionPageStatus: 'loading' | 'complete';
  priceHistoryPageStatus: 'loading' | 'complete';
  kpiPageStatus: 'loading' | 'complete';
}


@State<IGravityState>({
  name: 'GravityState',
  defaults: {
    priceHistory: [],
    priceHistoryPageStatus: 'loading',
    dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)],
    transactionDateRange: [new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)],
    kpiDateRange: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)],
    kpi: {
      overview: {
        clients: 0,
        totalEURO: 0,
        totalInvestEURO: 0,
        totalSGK: 0
      },
      details: {
        price: 0,
        EUROEnter: 0,
        EUROOut: 0
      }
    },
    transactionsDetails: {
      clients: 0,
      SGKEnter: 0,
      SGKOut: 0,
      transactionsNumber: 0,
      EUROEnter: 0,
      EUROOut: 0,
      transactions: [],
      incommingTransaction: [],
      transactionsOutgoing: [],
      filter: {
        type: null,
        search: null
      }
    },
    transactionPageStatus: 'loading',
    kpiPageStatus: 'loading'
  }
})

@Injectable()
export class GravityState {

  constructor(
    private gravityService: HttpGravityGateway
  ) {
  }

  @Action(SetDateRange)
  setDateRange(ctx: StateContext<IGravityState>, {payload}: SetDateRange) {
    ctx.patchState({dateRange: payload, priceHistoryPageStatus: 'loading'});
    ctx.dispatch(new GetPriceHistory());
  }

  @Action(SetFilter)
  setFilter(ctx: StateContext<IGravityState>, {payload}: SetFilter) {
    ctx.patchState({
      transactionsDetails: {
        ...ctx.getState().transactionsDetails,
        filter: {
          ...payload
        }
      }
    });
  }

  @Action(SetKpiDateRange)
  setKpiDateRange(ctx: StateContext<IGravityState>, {payload}: SetKpiDateRange) {
    ctx.patchState({kpiDateRange: payload});
    ctx.dispatch(new GetKpi());
  }

  @Action(GetKpi)
  getKpi(ctx: StateContext<IGravityState>) {
    ctx.patchState({kpiPageStatus: 'loading'});
    const dateRange = ctx.getState().kpiDateRange;
    return this.gravityService.getKpi(dateRange).pipe(
      tap((kpi) => {
        ctx.patchState({
          kpi,
          kpiPageStatus: 'complete'
        });
      })
    )
  }

  @Action(SetTransactionDateRange)
  setTransactionDateRange(ctx: StateContext<IGravityState>, {payload}: SetTransactionDateRange) {
    ctx.patchState({transactionDateRange: payload});
    ctx.dispatch(new GetTransaction());
  }

  @Action(GetPriceHistory)
  getPriceHistory(ctx: StateContext<IGravityState>) {
    ctx.patchState({priceHistoryPageStatus: 'loading'});
    const dateRange = ctx.getState().dateRange;
    return this.gravityService.getPriceHistory(dateRange).pipe(
      tap((priceHistory) => {
        ctx.patchState({priceHistory, priceHistoryPageStatus: 'complete'});
      })
    )
  }

  @Action(SetPrice)
  setPrice(ctx: StateContext<IGravityState>, {payload}: SetPrice) {
    return this.gravityService.setPrice(payload).pipe(
      tap(() => {
        ctx.dispatch(new SetPriceSuccess());
      })
    )
  }

  @Action(SetPriceSuccess)
  setPriceSuccess(ctx: StateContext<IGravityState>) {
    return of(null).pipe(
      delay(500),
      tap(() => {
        ctx.dispatch(new GetPriceHistory());
      })
    )
  }

  @Action(GetTransaction)
  getTransaction(ctx: StateContext<IGravityState>) {
    ctx.patchState({transactionPageStatus: 'loading'});
    const dateRange = ctx.getState().transactionDateRange;
    return this.gravityService.getTransaction(dateRange).pipe(
      tap((transactionsDetails) => {
        ctx.patchState({
          transactionsDetails: {...transactionsDetails, filter: {search: null, type: null}},
          transactionPageStatus: 'complete'
        });
      })
    )
  }
}

