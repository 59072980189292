import {Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext, Store,} from '@ngxs/store';
import {getAuth, indexedDBLocalPersistence, setPersistence,} from 'firebase/auth';

import {v4 as uuidv4} from 'uuid';
import {NavService, ReactiveLoaderService, ToastService, UserService,} from '@shared/services';
import {ERoles, ERoutes, EToastSeverity, IAuthStateModel, IRole, IUser} from '@models/dist';
import {
  confirmingPasswordReset,
  loadMyUser,
  LoadUserAfterTwoFa,
  Login,
  Logout,
  Register,
  ResetPassword,
  SendVerificationEmail,
  updateMfa,
  VerifyPasswordResetCode,
} from '@shared/stores/auth/auth.actions';
import {confirmPasswordReset, verifyPasswordResetCode} from '@firebase/auth';
import {Router} from '@angular/router';
import {reactiveButtonState} from '@shared/services/reactive-loader.service';
import {AuthService} from "@shared/services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {STORAGE_DEVICE_UID} from "@app/app.declaration";
import {environment} from "@environments/environment";
import LogRocket from "logrocket";

@State<IAuthStateModel>({
  name: 'AuthState',
  defaults: {
    refreshToken: null,
    user: null,
    roles: [],
    unread: 0,
    firebaseUser: null,
    loginError: null,
    registerError: null,
  },
})
@Injectable()
export class AuthState implements NgxsOnInit {
  auth = getAuth();

  roles: string[] = [];

  constructor(
    private navService: NavService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private userService: UserService,
    private toastService: ToastService,
    private reactiveLoader: ReactiveLoaderService,
    private store: Store
  ) {
  }

  @Selector()
  static getToken(state: IAuthStateModel): string | null {
    return state.refreshToken;
  }

  @Selector()
  static getUser(state: IAuthStateModel): any | null {
    return state.user;
  }

  @Selector()
  static hasUnread(state: IAuthStateModel): any {
    return state.unread
  }

  @Selector()
  static getPermissions(state: IAuthStateModel): string[] | undefined {
    if (!state.user?.roles) {
      return []
    }
    return state.user?.roles.flatMap((e: any) => e.permissions).map(({name}: any) => (name));
  }

  @Selector()
  static getRoles(state: IAuthStateModel): string[] | undefined {
    return state.user?.roles.map(({name}: { name: string }) => (name))
  }

  @Selector()
  static getFirebaseUser(state: IAuthStateModel): any {
    return state.firebaseUser;
  }

  @Selector()
  static getRegisterError(state: IAuthStateModel): any | null {
    return state.registerError;
  }

  @Selector()
  static getLoginError(state: IAuthStateModel): any | null {
    return state.loginError;
  }

  @Selector()
  static isAuthenticated(state: IAuthStateModel): boolean {
    return !!state.refreshToken;
  }

  getUniqueId(): string {
    return uuidv4();
  }

  isValidMfa(mfaD: any) {
    let summitId = localStorage.getItem(STORAGE_DEVICE_UID);
    if (!summitId) {
      summitId = this.getUniqueId();
      localStorage.setItem(STORAGE_DEVICE_UID, summitId);
    }
    if (mfaD.find((x: any) => x.d === summitId)) {
      return true;
    }
    return false;
  }

  ngxsOnInit(ctx: StateContext<IAuthStateModel>) {
    this.initState(ctx);
  }

  @Action(Login)
  async Login(ctx: StateContext<IAuthStateModel>, {payload}: Login) {
    this.reactiveLoader.setReactiveButtonState(
      'summit-login',
      reactiveButtonState.loading
    );
    try {
      await setPersistence(this.auth, indexedDBLocalPersistence);
      const res = await this.authService.login(payload.email, payload.password);
      ctx.patchState({firebaseUser: res?.user});

      try {
        await this.userService.triggerLogin(payload.email);
      } catch (e) {
        console.error(e);
      }

      if (res.user?.emailVerified) {

        this.store.dispatch(new loadMyUser())



        const userToken = await res.user.getIdTokenResult(true);
        const totp = (userToken?.claims?.mfaD as any)?.totp;
        const roles = userToken?.claims?.r?.sgp || [];
        if (!roles.includes(ERoles.ADMIN)) {

          this.toastService.addToast(EToastSeverity.error, "toast.login.serverError",
            "");
          this.store.dispatch(new Logout())

          ctx.patchState({
            loginError: {error: true, message: 'SERVER_ERROR'},
          });

          this.reactiveLoader.setReactiveButtonState(
            'summit-login',
            reactiveButtonState.error
          );
          return;
        }

        ctx.patchState({roles: roles});
        if ((totp && !this.isValidMfa(totp))) {
          this.router.navigate([ERoutes.AUTH, ERoutes.TWOFA]);
          this.reactiveLoader.setReactiveButtonState(
            'summit-login',
            reactiveButtonState.success
          );
          return;
        } else {
          this.toastService.addToast(EToastSeverity.error, "2FA.toast.connectionRefused",
            "2FA.toast.connectionRefusedExplained");
          this.store.dispatch(new Logout())

          ctx.patchState({
            loginError: {error: true, message: '2FA_NOT_ACTIVAtED'},
          });

          this.reactiveLoader.setReactiveButtonState(
            'summit-login',
            reactiveButtonState.error
          );
          return;
        }

      } else {
        ctx.patchState({
          loginError: {error: true, message: 'EMAIL_NOT_VERIFIED'},
        });

        this.reactiveLoader.setReactiveButtonState(
          'summit-login',
          reactiveButtonState.error
        );
        // if email is not verified we logout the user and redirect to verify email page

        this.store.dispatch(new Logout()).subscribe(() => {
          const userEmail = res?.user?.email as string;
          const userUid = res?.user?.uid as string;
          this.goToVerifyEmail(userEmail, userUid, true);
        });

      }

    } catch (error: any) {
      this.store.dispatch(new Logout());
      this.toastService.addToast(EToastSeverity.error, 'toast.login.titleError', 'toast.login.error');
      this.reactiveLoader.setReactiveButtonState(
        'summit-login',
        reactiveButtonState.error
      );
      if (error.message.includes('auth/user-not-found')) {
        ctx.patchState({
          loginError: {error: true, message: 'USER_NOT_FOUND'},
        });
      } else {
        ctx.patchState({
          loginError: {error: true, message: 'USER_NOT_FOUND'},
        });
      }
    }
  }

  @Action(Register)
  async Register(ctx: StateContext<IAuthStateModel>, {payload}: Register) {
    this.reactiveLoader.setReactiveButtonState(
      'summit-register',
      reactiveButtonState.loading
    );
    try {

      const user = await this.userService.createUser(payload) as IUser;

      const userEmail = payload.email;
      this.reactiveLoader.setReactiveButtonState(
        'summit-register',
        reactiveButtonState.success
      );
      this.toastService.addToast(EToastSeverity.success, 'toast.register.title', 'toast.register.create')

      this.goToVerifyEmail(userEmail, user.firebase_uuid, false);
    } catch (error) {
      console.log(error);
      this.toastService.addToast(EToastSeverity.error, 'toast.register.title', 'toast.register.errorCtn')

      this.reactiveLoader.setReactiveButtonState(
        'summit-register',
        reactiveButtonState.error
      );
    }
    return;
  }

  @Action(loadMyUser)
  async loadMyUser(ctx: StateContext<IAuthStateModel>) {
    ctx.patchState({
      user: null,
    });
    try {
      const user = await this.userService.getUser() as IUser;
      const {unread} = await this.userService.getAdminUnreadMsg() as { unread: number };
      if (!user) {
        throw new Error('User not found');
      }

      if (user?.passfortFormRequest) {
        user.passfortFormRequest = JSON.parse(user.passfortFormRequest as any);
      }
      const lang = user?.language?.toLowerCase();
      this.translate.use(lang);
      ctx.patchState({user, unread});
    } catch (error) {
      this.store.dispatch(new Logout());
    }
    return;
  }

  @Action(LoadUserAfterTwoFa)
  LoadUserAfterTwoFa(ctx: StateContext<IAuthStateModel>) {
    this.navService.addnav(['/admin/dashboard']);
    return;
  }

  @Action(Logout)
  async Logout(ctx: StateContext<IAuthStateModel>) {
    localStorage.removeItem(STORAGE_DEVICE_UID);
    try {
      await this.auth.signOut();
      ctx.patchState({
        refreshToken: null,
        user: null,
        firebaseUser: null,
        loginError: null,
        registerError: null,
      });
      this.navService.addnav([ERoutes.AUTH]);
    } catch (err) {
      console.error(err);
    }
    return;
  }

  @Action(confirmingPasswordReset)
  async confirmingPasswordReset(
    ctx: StateContext<IAuthStateModel>,
    {payload}: confirmingPasswordReset
  ) {
    try {
      this.reactiveLoader.setReactiveButtonState(
        'summit-confirm-password-reset',
        reactiveButtonState.loading
      );
      //TODO reset password with hash on DBB
      const res = await confirmPasswordReset(
        this.auth,
        payload.oobCode,
        payload.password
      );
      this.reactiveLoader.setReactiveButtonState(
        'summit-confirm-password-reset',
        reactiveButtonState.success
      );
      this.toastService.addToast(
        EToastSeverity.success,
        'success',
        'passwordSuccessfullyUpdated'
      );
      this.navService.addnav([ERoutes.AUTH]);
    } catch (err) {
      console.log(err);
      this.reactiveLoader.setReactiveButtonState(
        'summit-confirm-password-reset',
        reactiveButtonState.error
      );
      this.toastService.addToast(EToastSeverity.error, 'toast.error', err);
    }
  }

  @Action(VerifyPasswordResetCode)
  async VerifyPasswordResetCode(
    ctx: StateContext<IAuthStateModel>,
    {payload}: VerifyPasswordResetCode
  ) {
    try {
      this.reactiveLoader.setReactiveButtonState(
        'summit-verify-password-reset-code',
        reactiveButtonState.loading
      );
      const res = await verifyPasswordResetCode(this.auth, payload.oobCode);
      this.reactiveLoader.setReactiveButtonState(
        'summit-verify-password-reset-code',
        reactiveButtonState.success
      );
    } catch (err) {
      this.reactiveLoader.setReactiveButtonState(
        'summit-verify-password-reset-code',
        reactiveButtonState.error
      );
      console.error(err);
      this.toastService.addToast(EToastSeverity.error, 'toast.error', err);
      this.navService.addnav([ERoutes.AUTH]);
    }
  }

  @Action(ResetPassword)
  async ResetPassword(
    ctx: StateContext<IAuthStateModel>,
    {payload}: ResetPassword
  ) {
    try {
      this.toastService.addToast(
        EToastSeverity.info,
        'toast.password.title',
        'toast.password.running'
      );
      this.reactiveLoader.setReactiveButtonState(
        'summit-reset-password',
        reactiveButtonState.loading
      );
      await this.authService.resetPassword(payload.email);
      this.reactiveLoader.setReactiveButtonState(
        'summit-reset-password',
        reactiveButtonState.success
      );
      this.toastService.addToast(
        EToastSeverity.success,
        'toast.password.title',
        'toast.password.successEmail'
      );
      // this.navService.addnav([ERoutes.AUTH]);
    } catch (err) {
      this.reactiveLoader.setReactiveButtonState(
        'summit-reset-password',
        reactiveButtonState.error
      );
      this.toastService.addToast(
        EToastSeverity.error,
        'toast.password.title',
        'toast.password.error'
      );
      console.error(err);
    }
  }

  @Action(SendVerificationEmail)
  async SendVerificationEmail(
    ctx: StateContext<IAuthStateModel>,
    {payload}: SendVerificationEmail
  ) {
    if (payload.toast) {
      this.toastService.addToast(
        EToastSeverity.info,
        'toast.confirmation',
        'toast.password.running'
      );
    }
    try {
      this.reactiveLoader.setReactiveButtonState(
        'summit-send-verification-email',
        reactiveButtonState.loading
      );
      await this.userService.sendVerificationEmail(payload.email);
      this.reactiveLoader.setReactiveButtonState(
        'summit-send-verification-email',
        reactiveButtonState.success
      );
      if (payload.toast) {
        this.toastService.addToast(
          EToastSeverity.success,
          'toast.confirmation',
          'toast.emailVerified.resend'
        );
      }
      // this.navService.addnav([ERoutes.AUTH]);
    } catch (error) {
      this.reactiveLoader.setReactiveButtonState(
        'summit-send-verification-email',
        reactiveButtonState.error
      );
      this.toastService.addToast(
        EToastSeverity.error,
        'toast.error',
        'toast.password.error'
      );
      console.error(error);
    }
    return;
  }

  @Action(updateMfa)
  async updateMfa(ctx: StateContext<IAuthStateModel>, {payload}: updateMfa) {
    ctx.patchState({
      user: {
        ...ctx.getState().user,
        totpActivated: payload.mfa,
      },
    });
    return;
  }

  private initState(ctx: StateContext<IAuthStateModel>) {
    const that = this;
    this.auth.onAuthStateChanged((user) => {
      ctx.patchState({firebaseUser: user});
      if (environment.production) {
        LogRocket.identify(user?.uid!, {
          name: user?.displayName!,
          email: user?.email!,
        });
      }
      if (user?.emailVerified) {
        user.getIdTokenResult(true).then((idTokenResult) => {
          if (
            !idTokenResult.claims.adminId &&
            idTokenResult.claims.mfaD &&
            (idTokenResult.claims.mfaD as any).totp &&
            !that.isValidMfa((idTokenResult.claims.mfaD as any).totp)
          ) {
            this.router.navigate([ERoutes.AUTH, ERoutes.TWOFA]);
          } else {
            that.store.dispatch(new loadMyUser());
          }
        });
      }
    });
  }

  private goToVerifyEmail(userEmail: string, userUid?: string, resend = false) {
    this.router.navigate([`${ERoutes.AUTH}/${ERoutes.VERIFY_EMAIL}`], {
      replaceUrl: true,
      queryParams: {userEmail, userUid, resend},
    });
  }
}
