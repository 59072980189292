import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'TxHash'
})
export class TxPipe implements PipeTransform {
  transform(tx: any): any {
    return tx.substring(0, 10) + '....' + tx.substring(tx.length - 10, tx.length)
  }
}
